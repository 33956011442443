import React, { createContext, useState } from "react";
import axios from 'axios'
// import { id } from "date-fns/locale";
export const MainContext = createContext();

export const MainContextProvider = props => {

    // Root State
    const [state, setState] = useState({
        showLogin: true,
        isAuth: false,
        theUser: null,
    });

    const [userData, setUserData] = useState({
        loaded: false,
    });

    const [notifData , setNotifData] = useState([])
    const [systemData , setSystemData] = useState([])

    const pageUrl = 'https://futureinvest.cz/'
    const pageApiUrl = 'https://api.futureinvest.cz/'

    // Define the base URL
    const Axios = axios.create({
        baseURL: "https://api.futureinvest.cz/",
    });

    const [userAccountData, setUserAccountData] = useState({
        amount: 0,
        locked_amount: 0,
        currency: "czk"
    })

    const [systemNotif, setSystemNotif] = useState({
        success: '',
        error: ''
    })

    const [eurConverstion, setEurConversion] = useState(25.00)
    const [overlayContent, setOverlayContent] = useState(<></>)
    const [updateUi, setUpdateUi] = useState(false)
    const [agreementPdfLink, setAgreementPdfLink] = useState(null)

    if(updateUi){
        setTimeout(function(){
            setUpdateUi(false)
        }, 500)
    }

    // On Click the Log out button
    const logoutUser = () => {
        localStorage.removeItem('loginToken');
        setState({
            ...state,
            isAuth: false
        })
    }

    const registerUser = async (user) => {
        // Sending the user registration request
        const register = await Axios.post('register.php', {
            username: user.username,
            password: user.password,
            name: user.name,
            surname: user.surname,
            parent: user.parent
        });

        return register.data;
    }

    const changePassword = async (user) => {
        // Sending the user registration request
        const changePass = await Axios.post('change-password.php', {
            user_id: user.user_id,
            password: user.password,
        });

        return changePass.data;
    }


    const loginUser = async (user) => {
        // Sending the user Login request
        const login = await Axios.post('login.php', {
            name: user.name,
            password: user.password
        });
        return login.data;
    }

    const updateAcountBalance = async (userData) => {
        let getUserAccount = await getTableWhereApi('user_accounts', 'id', userData.id, 'int') 
        if(getUserAccount.success !== undefined && getUserAccount.success === 0){
            console.log('>> getUserAccount failed status: ' + getUserAccount.status + ' ' + getUserAccount.message)
            setUserAccountData({
                amount: 0,
                locked_amount: 0
            });
        } else {
            setUserAccountData(getUserAccount[0])
        }
        
    }

    const getEur = async () => {
        let reqSqlExchangeRate = await getTableWhereApi("system", "val_key", "eur_to_czk", "string")
        if (!Array.isArray(reqSqlExchangeRate) || reqSqlExchangeRate.length === 0){
            console.warn("can't get eur exchange rate from sql")
            return
        }
        let sqlExchangeRate = reqSqlExchangeRate[0]

        setEurConversion(parseFloat(sqlExchangeRate.value))

        // get new rate if it was not changed in last 24h
        var timeStamp = Math.round(new Date().getTime() / 1000)
        var timeStampYesterday = timeStamp - (24 * 3600)
        if (convertTimestamp(sqlExchangeRate.date) < new Date(timeStampYesterday * 1000).getTime()){

            const url = "http://api.exchangeratesapi.io/v1/latest?access_key=9521c9d6607eceff582bfcc3b5325796&format=1"
            const Http = new XMLHttpRequest();
            Http.open("GET", url);
            Http.send();
        
            Http.onreadystatechange = (e) => {
                if (Http.readyState === 4 && Http.status === 200) {
                    let res = Http.responseText
                    let api_obj = JSON.parse(res) 
                    if (api_obj.success){
                        console.log("> EUR - CZK: ", api_obj.rates.CZK)

                        if (parseFloat(sqlExchangeRate.value).toFixed(2) !== parseFloat(api_obj.rates.CZK).toFixed(2)){
                            // update exchange rate in sql
                            updateExRate(api_obj.rates.CZK.toFixed(2), parseFloat(sqlExchangeRate.value).toFixed(2))
                        }
                    }            
                }
            }
        }

        const updateExRate = async (exRate, oldExRate) => {
            let newExRate = sqlExchangeRate
            newExRate.value = exRate
            newExRate.date = getTimestemp()
            let postData = {
                data: [newExRate],
                changes: [0],
                post_table: 'system'
            }

            let postResp = await postTableApi(postData)
            if (!postResp.hasOwnProperty('success') || postResp.success !== 1) {
                console.error("updateExRate(): " + postResp)
                return
            }

            console.log(">> exchange rate was updated " + oldExRate + " -> " + exRate)
        }
    }

    const setSystemDataObj = (sysData) => {
        let sysDataObj = {}
        for(let i = 0; i < sysData.length; i++){
            let data = sysData[i]
            sysDataObj[data.item] = data.value;
        }
        setSystemData(sysDataObj)
    }

    // Checking user logged in or not
    const isLoggedIn = async () => {
        const loginToken = localStorage.getItem('loginToken');
        let returnData = false
        if (loginToken) {
            Axios.defaults.headers.common['Authorization'] = 'bearer ' + loginToken;
            const params = new URLSearchParams([['auth', 'bearer ' + loginToken]]);

            const { data } = await Axios.get('user-info.php', { params });

            console.log("-->", data);

            // If user information is successfully received
            if (data.success && data.user) {
                setState({
                    showLogin: false,
                    isAuth: true,
                    theUser: data.user,
                });

                if(data.user.id){
                    console.log(">> token login sucess isAuth:", state.isAuth, "user_id:", data.user.id)
                }
                returnData = true

                let getUserData = await getTableWhereApi('user_data', 'id', data.user.id, 'int') 
                if (Array.isArray(getUserData)){
                    setUserData(getUserData[0])
                }

                checkNotifications(getUserData[0])
                // check for new notifications every 30s 
                    // disabled for now
                // setInterval(function(){
                //     if(state.isAuth){
                //         checkNotifications(getUserData[0])
                //         console.log(">> check notif")
                //     }
                // }, 60000)

                updateAcountBalance(data.user)

                getEur()

                let getSystemData = await getTableApi('system_data')
                setSystemDataObj(getSystemData)

            } else {
                console.log(">> token login failed data: " + data.success + " user: " + data.user, data)
                setState({
                    showLogin: true,
                    isAuth: false,
                    theUser: null,
                });
                returnData = false
            }
        }
        return returnData
    }

    // get-table api:
    // Header must inclued -> Authorization: bearer logintoken...
    // Data structure: 
        // get_table: < name of table to get content from >

    const getTableApi = async (tableName) => {
        const loginToken = localStorage.getItem('loginToken');

        if (loginToken) {
            Axios.defaults.headers.common['Authorization'] = 'bearer ' + loginToken;
            const params = new URLSearchParams([['auth', 'bearer ' + loginToken]]);

            const content = await Axios.post('get-table.php', {
                get_table: tableName
            }, {params});
            return content.data
        }
    }

    // get-table-where api:
    // Header must inclued -> Authorization: bearer logintoken...
    // Data structure:
        // get_table: < name of table to get content from >
        // where_item: < name of table column -> id >
        // where_data: < what to wind WHERE -> 10001 >
        // type: "string" | "int""

    const getTableWhereApi = async (tableName, whereItem, whereData, type) => {
        const loginToken = localStorage.getItem('loginToken');

        if (loginToken) {
            Axios.defaults.headers.common['Authorization'] = 'bearer ' + loginToken;
            const params = new URLSearchParams([['auth', 'bearer ' + loginToken]]);

            const content = await Axios.post('get-table-where.php', {
                get_table: tableName,
                where_item: whereItem,
                where_data: whereData,
                type: type
            }, {params});

            return content.data
        }
    }

    // post-table api:
        // Header must inclued -> Authorization: bearer logintoken...
        // Data structure: 
            // table_content{
            //     data: [{ < whole content of a table > }],
            //     changes: [ < indexes of lines in data to be updated (start with 0) > ],
            //     post_table: < name of table to update >
            // }

    const postTableApi = async (tableObject) => {
        const loginToken = localStorage.getItem('loginToken');

        if (loginToken) {
            Axios.defaults.headers.common['Authorization'] = 'bearer ' + loginToken;
            const params = new URLSearchParams([['auth', 'bearer ' + loginToken]]);

            // Axios.defaults.headers.common['Authorization'] = 'bearer ' + loginToken;
            // const params = new URLSearchParams([['auth', 'bearer ' + loginToken]]);
            const content = await Axios.post('post-table.php', {
                table_content: tableObject
            }, {params});
            return content.data
        }
    }

    // insert-table api:
        // Header must inclued -> Authorization: bearer logintoken...
        // Data structure: 
            // table_content{
            //     data: { < whole content of a line > },
            //     post_table: < name of table to update >
            // }

    const insertTableApi = async (tableObject, withId = false) => {
        const loginToken = localStorage.getItem('loginToken');

        if (loginToken) {
            Axios.defaults.headers.common['Authorization'] = 'bearer ' + loginToken;
            const params = new URLSearchParams([['auth', 'bearer ' + loginToken]]);

            const content = await Axios.post('insert-table.php', {
                table_content: tableObject,
                with_id: withId
            }, {params} );
            return content.data
        }
    }


    // remove-from-table api:
    // Header must inclued -> Authorization: bearer logintoken... (admin only)
    // Data structure:
        // get_table: < name of table to remove record from>
        // where_item: < name of table column -> id >
        // where_data: < what to wind WHERE -> 10001 >
        // type: "string" | "int""

    const removeTableApi = async (tableName, whereItem, whereData, type) => {
        const loginToken = localStorage.getItem('loginToken');

        if (loginToken) {
            Axios.defaults.headers.common['Authorization'] = 'bearer ' + loginToken;
            const params = new URLSearchParams([['auth', 'bearer ' + loginToken]]);

            const content = await Axios.post('remove-table.php', {
                get_table: tableName,
                where_item: whereItem,
                where_data: whereData,
                type: type
            }, {params});

            return content.data
        }
    }


    // mail-send api:
        // Header must inclued -> Authorization: bearer logintoken...
        // Data structure: 
            // mail_content{
            //     mail_to: < reciver mail address >,
            //     subject: < subject >,
            //     message: < message >
            // }

    const mailApi = async (mailObject) => {
        const loginToken = localStorage.getItem('loginToken');

        if (loginToken) {
            Axios.defaults.headers.common['Authorization'] = 'bearer ' + loginToken;
            const params = new URLSearchParams([['auth', 'bearer ' + loginToken]]);

            const content = await Axios.post('mail-send.php', {
                mail_content: mailObject
            }, {params});
            return content.data
        }
    }

    // sms-send-message.php api:   // Header must inclued -> Authorization: bearer logintoken…
    // smsMessages = [{
    //     phone: "123444",
    //     message: "aaaaaa"
    //  },{
    //     phone: "53434",
    //     message: "bbbb"
    //  }]
    // Data return: 
    // json {“success”:1,”status”:200,”message”:”Sucessful”, “code_id”: 1}

    const sendSMS = async (smsMessages) => {
        const loginToken = localStorage.getItem('loginToken');

        if (loginToken) {
            Axios.defaults.headers.common['Authorization'] = 'bearer ' + loginToken;
            const params = new URLSearchParams([['auth', 'bearer ' + loginToken]]);

            console.log({
                smsMessages: smsMessages,
            })

            const content = await Axios.post('sms-send-message.php', {
                smsMessages: smsMessages,
            }, { params });
            return content.data
        }
    }


    // sms-code-get.php api:   // Header must inclued -> Authorization: bearer logintoken…
        // Data return: 
            // json {“success”:1,”status”:200,”message”:”Sucessful”, “code_id”: 1}

    const getSMSCode = async () => {
        const loginToken = localStorage.getItem('loginToken');

        if (loginToken) {
            Axios.defaults.headers.common['Authorization'] = 'bearer ' + loginToken;
            const params = new URLSearchParams([['auth', 'bearer ' + loginToken]]);
            let userPhone = userData.phone.replace(/\s/g, '')

            if(userPhone.length === 6){
                userPhone = "+420" + userPhone
            }
            const content = await Axios.post('sms-code-get.php', {
                user_id: userData.id,
                user_phone: userPhone
            }, {params});
            return content.data
        }
    }

    // sms-code-verify.php api:   // Header must inclued -> Authorization: bearer logintoken…
        // Data request:
            // json {“code_id”: 1, ”code”: 377298} (code from user)
        // Data return : 
            // json {“success”:1,”status”:200,”message”:”Sucessful code is Valid”}

    const verifySMSCode = async (code, code_id) => {
        const loginToken = localStorage.getItem('loginToken');

        if (loginToken) {
            Axios.defaults.headers.common['Authorization'] = 'bearer ' + loginToken;
            const params = new URLSearchParams([['auth', 'bearer ' + loginToken]]);

            const content = await Axios.post('sms-code-verify.php', {
                user_id: userData.id,
                code_id: code_id,
                code: code,

            }, {params});
            return content.data
        }
    }

    // convert timestemp string to js Date() format
    const convertTimestamp = (timestemp) => {
        let slitDateTime = timestemp.split('_')
        let slitDate = slitDateTime[0].split('.')
        let slitTime = slitDateTime[1].split(':')

        let date = new Date(parseInt(slitDate[2]), parseInt(slitDate[1]) - 1, parseInt(slitDate[0]), parseInt(slitTime[0]), parseInt(slitTime[1]), parseInt(slitTime[2]))
        return date
    }

    const humanFileSize = (bytes, dp = 1) => {
        const thresh = 1024;
        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }
        const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        let u = -1;
        const r = 10 ** dp;
        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
        return bytes.toFixed(dp) + ' ' + units[u];
    }

    const updateUserData = async () => {
        let getUserData = await getTableWhereApi('user_data', 'id', userData.id, 'int') 
        setUserData(getUserData[0])
    }

    const numFormat = (number, targetLength) => {
        var output = number + '';
        while (output.length < targetLength) {
            output = '0' + output;
        }
        return output;
    }

    const getTimestemp = (date = new Date()) => {
        if(date === null){
            return ""
        }
        let date_today = numFormat(date.getDate(), 2) + '.' +  numFormat((date.getMonth() + 1), 2) + '.' + date.getFullYear() + '_' + numFormat(date.getHours(), 2) + ':' + numFormat(date.getMinutes(), 2) + ':' + numFormat(date.getSeconds(), 2)
        return date_today
    }

    const checkNotifications = async (user) => {

        // errors !!!
        // if(!state.isAuth){
        //     setNotifData({ 
        //         data: [],
        //         unread: [],
        //         newNotif: false
        //     })
        //     return
        // }
        let notifArray = await getTableWhereApi('notifications', 'user_id', user.id, 'int')
        let notifArrayForAll = await getTableWhereApi('notifications', 'destination', 0, 'int')

        if((notifArray && notifArray.hasOwnProperty('success') && notifArray.success === 0) 
            && (notifArrayForAll && notifArrayForAll.hasOwnProperty('success') && notifArrayForAll.success === 0)){
            setNotifData({ 
                data: [],
                unread: [],
                newNotif: false
            })
            return
        }

        if(!notifArray[0]){
            setNotifData({ 
                data: [],
                unread: [],
                newNotif: false
            })
            return
        }

        // join notifications for user (1) and for all (0) sort and remove duplicates and reverse order

        // NOTE: maybe bullshit ?? unread parameter does not make sence do not joint this !!! TODO!! 
        notifArray = notifArray.concat(notifArrayForAll)
        notifArray.sort(function(a, b) {
            return a.id - b.id;
        });
        let tempArray = [notifArray[0]]
        let prevId = notifArray[0].id

        for(let i = 1; i < notifArray.length; i++){
            if(notifArray[i].id !== prevId){
                tempArray.push(notifArray[i])
            }
            prevId = notifArray[i].id
        }
        tempArray.reverse()
        notifArray = tempArray
        
        let newNotifs = false
        let unreadNotifs = []
        if(notifArray){
            notifArray = notifArray.slice(0, 10)
    
            for(let i = 0; i < notifArray.length; i++){
                if(notifArray[i].unread === "1"){
                    newNotifs = true
                    unreadNotifs.push(notifArray[i])
                }
            }
        }

        setNotifData({ 
            data: notifArray,
            unread: unreadNotifs,
            newNotif: newNotifs
        })
    }

    const readNotifications = async (unread) => {
        unread.forEach((e) => { 
            e.unread = "0"
        })

        let postRequest = {
            data: unread,
            changes: Array.from(Array(unread.length).keys()),
            post_table: 'notifications'
        }
        postTableApi(postRequest)
    }

    const calculateInterest = (total, years, ratePercent, roundToPlaces) => {
        let interestRate = total * (ratePercent / 100)
        return (interestRate * years).toFixed(roundToPlaces)
    }

    const daysBetweenTwoDates = (firstDate, secondDate) => {
        const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
        const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay))
        return diffDays
    }

    const getError = (text) => {
        console.error(">> Error: " + text)
        setSystemNotif({
            error: <div className="notification notification--error">{text}</div>
        })
        setTimeout(function () {
            setSystemNotif({ error: '' })
        }, 6000)
    }

    const getSuccess = (text) => {
        setSystemNotif({
            success: <div className="notification notification--success">{text}</div>
        })
        setTimeout(function () {
            setSystemNotif({ success: '' })
        }, 4000)
    }

    const [actualPage, setActualPage] = useState('/');

    const contextValue = {
        rootState: state,
        isLoggedIn: isLoggedIn,
        registerUser: registerUser,
        changePassword: changePassword,
        loginUser: loginUser,
        logoutUser: logoutUser,
        actualPage: actualPage,
        setActualPage: setActualPage,
        systemData: systemData,
        getTableApi: getTableApi,
        getTableWhereApi: getTableWhereApi,
        postTableApi: postTableApi,
        insertTableApi: insertTableApi,
        removeTableApi: removeTableApi,
        Axios: Axios,
        getTimestemp: getTimestemp,
        pageUrl: pageUrl,
        pageApiUrl: pageApiUrl,
        humanFileSize: humanFileSize,
        userData: userData,
        userAccountData: userAccountData,
        notifData: notifData,
        readNotifications: readNotifications,
        checkNotifications: checkNotifications,
        systemNotif: systemNotif,
        setSystemNotif: setSystemNotif,
        updateUserData: updateUserData,
        setOverlayContent: setOverlayContent,
        overlayContent: overlayContent,
        mailApi: mailApi,
        updateAcountBalance: updateAcountBalance,
        updateUi: updateUi,
        setUpdateUi: setUpdateUi,
        calculateInterest: calculateInterest,
        agreementPdfLink: agreementPdfLink,
        setAgreementPdfLink: setAgreementPdfLink,
        getSMSCode: getSMSCode,
        verifySMSCode: verifySMSCode,
        convertTimestamp: convertTimestamp,
        numFormat: numFormat,
        daysBetweenTwoDates: daysBetweenTwoDates,
        getError: getError,
        getSuccess: getSuccess,
        eurConverstion: eurConverstion,
        sendSMS: sendSMS,
    }
    return (
        <MainContext.Provider value={contextValue}>
            {props.children}
        </MainContext.Provider>
    )
}

export default MainContextProvider;