/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import "./css/ConfirmDialog.css"

function ConfirmDialog(props) {
    return(<>
        <div id="confirm" className={props.type}>
            <div className="centered-box">
                <div className="container content_box  confrim_box ">
                    
                    <div className="confirm_wrapper">
                        <h3 className="text-center confirm_text">{props.text}</h3>

                        <div className="center_buttons_grid">
                            <button className="button button--small button--outlined" onClick={props.close} >
                                zrušit
                            </button>
                            <button className="button button--small" onClick={props.confirm} >
                                Ano
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>)
}

export default ConfirmDialog