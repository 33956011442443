/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import "./css/UserDetail.css"
import { CircleProgress } from 'react-gradient-progress'
import { MainContext } from '../contexts/MainContext'
import profile_pic from '../img/profile_pic.svg'
import Loading from './Loading'
import Error404 from '../pages/Error404'
import IdentityDocuments from './IdentityDocuments'
import { Link } from 'react-router-dom'
import ChangeParent from './ChangeParent'
import ConfirmDialog from './ConfirmDialog'
import UserDocuments from './UserDocuments'
import UserInvestmentOverview from './UserInvestmentOverview'


function UserDetail(props) {
    const { getError, pageApiUrl, getTableWhereApi, calculateInterest,
        convertTimestamp, postTableApi, getSuccess } = useContext(MainContext)
    
    const [userStatus, setUserStatus] = useState({
        status: 'neznámý',
        class: '',
        statusCode: 0
    })
    const [thisUser, setThisUser] = useState(props.user)
    const [showLoading, setShowLoading] = useState(false)
    const [actualPage, setActualPage] = useState(0)
    const [userSysData, setUserSysData] = useState(null)
    const [userIdentDocs, setUserIdentDocs] = useState([])
    const [childUsers, setChildUsers] = useState([])
    const [parentInfo, setParentInfo] = useState([])
    const [showChangeParentDialog, setShowChangeParentDialog] = useState(false)
    const [userAccountData, setUserAccountData] = useState({
        id: null,
        name: "",
        accountAmount: 0,
        accountAmountLocked: 0,
        userProfile: "",
        grInvested: 0,
        grAvaliable: 0,
        parent: "",
        address: null,
        secAddress: null,
        userIdCard: null,
        userDrivingLicense: null
    })

    const [payoutData, setPayoutData] = useState({
        payoutTotal: 0,
        averagePayout: 0,
        countInvestments: 0
    })

    // const [fundsInRsSwitch, setFundsInRsSwitch] = useState(true)
    const [showRemoveUserDialog, setShowRemoveUserDialog] = useState(false)


    useEffect(() => {
        getUserData()
    }, [])

    useEffect(() => {
        if (userAccountData.id !== null && userSysData !== null) {
            calculateNextPayout()
            getUserIdentDocs()
            getChildUsers()
            getParentUsers()
        }
    }, [userAccountData, userSysData, thisUser])

    const getUserData = async () => {
        setShowLoading(true)
        let getUserData = thisUser

        let userAccount = await getTableWhereApi('user_accounts', 'id', getUserData.id, 'int')
        if (!Array.isArray(userAccount) || userAccount.length === 0) {
            getError("Nastala chyba: " + userAccount)
            setShowLoading(false)
            return
        }
        userAccount = userAccount[0]

        let graphValueInvested = 0
        let graphValueAvaliable = 0

        if (parseInt(userAccount.amount) !== 0) {
            graphValueInvested = Math.round(userAccount.locked_amount / userAccount.amount * 100)
            graphValueAvaliable = 100 - Math.round(userAccount.locked_amount / userAccount.amount * 100)
        }
        let userParent = ""

        if (getUserData.parent !== "0"){
            let parentData = await getTableWhereApi('user_data', 'id', getUserData.parent, 'int')
            if (!Array.isArray(parentData) || parentData.length === 0) {
                getError("Nastala chyba: " + parentData)
                setShowLoading(false)
                return
            }
            parentData = parentData[0]
            userParent = parentData.name + " " + parentData.surname + " ("+parentData.id+")"

        }    

        setUserAccountData({
            id: getUserData.id,
            name: getUserData.name + " " + getUserData.surname,
            accountAmount: userAccount.amount,
            accountAmountLocked: userAccount.locked_amount,
            userProfile: getUserData.profile_picture,
            grInvested: graphValueInvested,
            grAvaliable: graphValueAvaliable,
            parent: userParent,
            address: JSON.parse(getUserData.primary_address),
            secAddress: JSON.parse(getUserData.secondary_address),
            userIdCard: JSON.parse(getUserData.identity_card),
            userDrivingLicense: JSON.parse(getUserData.driving_license)
        })

        // if (thisUser.funds_in_rs === '0'){
        //     setFundsInRsSwitch(false)
        // }

        getUserStatus()

    }

    const getUserStatus = async () => {
        let userAccount = await getTableWhereApi('users', 'id', thisUser.id, 'int')
        if (!Array.isArray(userAccount) || userAccount.length === 0) {
            getError("Nastala chyba: " + userAccount)
            setShowLoading(false)
            return
        }
        setUserSysData(userAccount[0])
        userAccount = userAccount[0]
        let statusString = ''
        let statusClass = ''
        let statusCode = parseInt(userAccount.status)

        if (statusCode === 1) {
            statusString = 'v pořádku'
            statusClass = 'active_user'
        } else if (statusCode === 2) {
            statusString = 'zablokovaný'
            statusClass = 'error'
        } else if (statusCode === 0) {
            statusString = 'neaktivní'
            statusClass = 'error'
        }

        setUserStatus({
            status: statusString,
            class: statusClass,
            statusCode: statusCode
        })
        setShowLoading(false)
    }

    const calculateNextPayout = async () => {
        // get all user investments
        let requestActiveInvestments = await getTableWhereApi('investments', 'user_id', userAccountData.id, 'int')
        if (!Array.isArray(requestActiveInvestments)) {
            console.warn("> Error calculateNextPayout()", requestActiveInvestments)
            //getError("Nastala chyba při načítání investic. [calculateNextPayout()]")
            return
        }
        const dateToday = new Date()
        requestActiveInvestments = requestActiveInvestments.filter(item => dateToday <= convertTimestamp(item.end_date))        // recheck (<) investment last day

        let investmentsCount = 0
        let totalPaidOut = 0

        // for each calc interest
        // calculate intereset per investment and add them up to user  
        requestActiveInvestments.forEach((item, i) => {
            let interest = Math.floor(calculateInterest(item.amount, item.length, item.interest, 2) / (12 * item.length))
            totalPaidOut += interest
            investmentsCount++
        })
        let averagePayout = Math.floor(totalPaidOut / requestActiveInvestments.length)
        setPayoutData({
            payoutTotal: totalPaidOut,
            averagePayout: averagePayout,
            countInvestments: investmentsCount,
        })
    }

    const handelCallBack = async (status) => {
        if (status.error === true) {
            setUserStatus({ status: status.string, class: "error" })
        } else {
            setUserStatus({ status: "v pořádku", class: "ok" })
        }
    }

    const getUserIdentDocs = async () => {
        let reqUserIdent = await getTableWhereApi('user_identification', 'user_id', userAccountData.id, 'int')
        if (!Array.isArray(reqUserIdent)) {
            console.warn("> Error getUserIdentDocs()", reqUserIdent)
            //getError("Nastala chyba při načítání investic. [getUserIdentDocs()]")
            return
        }
        let reqUserIdentAr = []
        reqUserIdent.forEach((data, i) => {
            if (data.front_img){
                reqUserIdentAr.push({
                    file: data.front_img,
                    date: data.timestemp,
                    type: data.type
                })
            }
            if (data.back_img){
                reqUserIdentAr.push({
                    file: data.back_img,
                    date: data.timestemp,
                    type: data.type
                })
            }
        })

        setUserIdentDocs(reqUserIdentAr)
    }

    const getChildUsers = async () => {
        let reqChildUsers = await getTableWhereApi('user_data', 'parent', userAccountData.id, 'int')
        if (!Array.isArray(reqChildUsers)) {
            console.warn("> Error getUserIdentDocs()", reqChildUsers)
            //getError("Nastala chyba při načítání investic. [getUserIdentDocs()]")
            return
        }
        setChildUsers(reqChildUsers)
    }


    const getParentUsers = async () => {
        let reqChildUsers = await getTableWhereApi('user_data', 'id', thisUser.parent, 'int')
        if (!Array.isArray(reqChildUsers)) {
            console.warn("> Error getUserIdentDocs()", reqChildUsers)
            //getError("Nastala chyba při načítání investic. [getUserIdentDocs()]")
            return
        }
        setParentInfo(reqChildUsers[0])
    }

    const handleCloseCP = async () => {
        setShowChangeParentDialog(false)
        let reqUserData = await getTableWhereApi('user_data', 'id', thisUser.id, 'int')
        if (!Array.isArray(reqUserData)) {
            console.warn("> Error getUserIdentDocs()", reqUserData)
            //getError("Nastala chyba při načítání investic. [getUserIdentDocs()]")
            return
        }

        setThisUser(reqUserData[0])
    }

    const handleCloseConfirmRemove = () => {
        setShowRemoveUserDialog(false)
    } 

    const handleRemoveUser = async () => {
        let getUserData = userSysData
        getUserData.status = "0"
        let requestData = {
            data: [getUserData],
            changes: [0],
            post_table: 'users'
        }

        console.log(requestData)

        let reqSetUserInactive = await postTableApi(requestData)

        if (reqSetUserInactive && reqSetUserInactive.hasOwnProperty('success') && reqSetUserInactive.success === 1) {
            getSuccess("uživatel byl deaktivován")
            setShowRemoveUserDialog(false)
            return
        } else {
            getError("Nastala chyba: " + reqSetUserInactive)
        }
    }


    if (showLoading) {
        return (<>
            <Loading />
        </>)
    }

    if (showChangeParentDialog){
        return (<>
            <ChangeParent close={handleCloseCP} user={props.user} />
        </>)
    }

    if (showRemoveUserDialog){
        return (<>
            <ConfirmDialog text={"Opravdu chcete odstranit uživatele " + userAccountData.name + " " + userAccountData.id + " ?"} confirm={handleRemoveUser} close={handleCloseConfirmRemove}  />
        </>)
    }

    if (actualPage === 0){
        return(<>
            <div id="user_detail">
                <div className="close_bnt" onClick={props.close}></div>

                <div className="centered-box scroll_box">
                    
                    <div className="container content_box component_cb cb-large user_detail">
                        <div className="fullpage_component_headline">
                            <h3 className="cb_fullpage_label_right">Detail klienta</h3>
                            <p className="user_status">Status <span className={`user_status_inner ${userStatus.class}`}>{userStatus.status}</span></p>
                        </div>

                        <div className="page_switch_wrapper">
                            <div className="page pg1 active_page" onClick={() => setActualPage(0)}>
                                <p>Základní informace</p>
                            </div>
                            <div className="page pg2" onClick={() => setActualPage(1)}>
                                <p>Investice a Smlouvy</p>
                            </div>
                        </div>

                        <div className='user_detail_grid'>
                            <div className='user_detail_grid_left_side'>

                                <div className="container_wrapper account_detail">
                                    <div className="container content_box component_cb udt_account_detail">

                                        <div className='user_info'>
                                            {userAccountData.userProfile !== '' && userAccountData.userProfile ?
                                                <img src={pageApiUrl + 'uploads/' + userAccountData.userProfile} className="profile_img" alt="user_profile" /> :
                                                <img src={profile_pic} className="profile_img" alt="user_profile" />
                                            }
                                            <p>{userAccountData.name}</p>
                                        </div>


                                        <h2 className="account_balance">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(userAccountData.accountAmount)}</h2>

                                        <div className="po_grid">

                                            <div className="circular_progressbar_box">
                                                <div className="circular_progressbar">
                                                    {window.innerWidth < 1400 ?
                                                        <CircleProgress percentage={userAccountData.grInvested} primaryColor={['#71E7FF', '#00BAFF']} strokeWidth={3} width={60} fontColor='#2F3E68' secondaryColor='#EEEEEE' />
                                                        : <CircleProgress percentage={userAccountData.grInvested} primaryColor={['#71E7FF', '#00BAFF']} strokeWidth={4} width={70} fontColor='#2F3E68' secondaryColor='#EEEEEE' />
                                                    }
                                                </div>
                                                <div className="textbox">
                                                    <p className="amount">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(userAccountData.accountAmountLocked)}</p>
                                                    <p className="amount_label">v investicích</p>
                                                </div>
                                            </div>

                                            <div className="circular_progressbar_box">
                                                <div className="circular_progressbar">
                                                    {window.innerWidth < 1400 ?
                                                        <CircleProgress percentage={userAccountData.grAvaliable} primaryColor={['#6404CB', '#A95BFD']} strokeWidth={3} width={60} fontColor='#2F3E68' secondaryColor='#EEEEEE' />
                                                        : <CircleProgress percentage={userAccountData.grAvaliable} primaryColor={['#6404CB', '#A95BFD']} strokeWidth={4} width={70} fontColor='#2F3E68' secondaryColor='#EEEEEE' />
                                                    }
                                                </div>
                                                <div className="textbox">
                                                    <p className="amount">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(userAccountData.accountAmount - userAccountData.accountAmountLocked)}</p>
                                                    <p className="amount_label">k dispozici</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container_wrapper payout_info">
                                    <div className="container content_box component_cb udt_payout_info">
                                        <p className="cb_label_right">vyplácení úroků</p>

                                        <div className='interest_calc'>
                                            <div className='interest_calc_warpper'>
                                                <h3 className="interest_amount">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(payoutData.payoutTotal)}</h3>
                                                <p>vypočtený úrok</p>
                                            </div>
                                            <div className='funds_in_rs_wrapper'>
                                                <div className="switch_container">
                                                    <p>Nechávat vyplacený úrok na účtu Future Invest: <span className='blue'>{thisUser.funds_in_rs == '1' ? 'ANO' : 'NE'}</span></p>
                                                    {/* <input type="checkbox" id="switch_ui 1" className="switch_ui" defaultChecked={fundsInRsSwitch} />
                                                    <label htmlFor="switch_ui 1" className="switch_ui_label in_box_switch"></label> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container_wrapper user_identity">
                                    <div className="container content_box component_cb grid_box">
                                        <p className="cb_label_left">Doklady</p>
                                        <IdentityDocuments edit={false} callBack={handelCallBack} user={props.user} />
                                    </div>
                                </div>

                                <div className="container_wrapper user_identity_docs">
                                    <div className="container content_box component_cb grid_box">
                                        <p className="cb_label_left">Soubory</p>

                                        <div className='user_file_grid'>
                                            {Array.isArray(userIdentDocs) && userIdentDocs.length !== 0 ? userIdentDocs.map((data, i) => {

                                                return (
                                                    <a key={i} href={pageApiUrl + 'uploads/' + data.file} target="_blank" rel="noreferrer"  ><img className='grid_item_prew' src={pageApiUrl + 'uploads/' + data.file} alt="user documents" /></a>
                                                )

                                            }) : <p className="empty_label">Nebyly nalezeny žádné soubory.</p>}
                                        </div>

                                    </div>
                                </div>

                                <div className="container_wrapper change_pass">
                                    <div className='container content_box'>
                                        <div className="action_context_box_wrpaper">
                                            <p className="label">Změna hesla účtu</p>
                                            <Link to={"/change_pass?user_id=" + userAccountData.id } ><button className="button button--small button--outlined change_pass_btn">Změnit</button></Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="container_wrapper child_users">
                                    <div className="container content_box component_cb grid_box">
                                        <p className="cb_label_left">Podřazené osoby</p>

                                        <div className='child_users_grid'>
                                            <div className="itw_line line_legend">
                                                <p>jméno</p>
                                                <p>obchodník</p>
                                            </div>

                                            {Array.isArray(childUsers) && childUsers.length !== 0 ? childUsers.map((data, i) => {

                                                return (
                                                    <div className='inv_line_box' key={i}>
                                                        <p>{data.name + " " + data.surname}</p>
                                                        <p>{userAccountData.name}</p>
                                                    </div>
                                                )

                                            }) : <p className="empty_label">Nebyly nalezeny žádné podřazené osoby.</p>}
                                        </div>

                                    </div>
                                </div>



                            </div>
                            <div className='user_detail_grid_right_side'>
                                {userSysData !== null && userAccountData.id !== null &&
                                    <div className='user_data_box'>
                                        <h3>Informace</h3>
                                        <div className='info_grid'>
                                            <p className='label'>id klienta</p> <p className='data'>{userAccountData.id}</p>
                                            <p className='label'>jméno</p> <p className='data'>{userAccountData.name}</p>
                                            <p className='label'>oprávnění</p> <p className='data'>{userSysData.user_type}</p>
                                            <p className='label'>nadřazená osoba</p> <p className='data'>{userAccountData.parent}</p>
                                            <p className='label'>email</p> <p className='data'>{props.user.email}</p>
                                            <p className='label'>telefon</p> <p className='data'>{props.user.phone}</p>
                                            <p className='label'>úroky na FTI účet</p> <p className='data'>{thisUser.funds_in_rs}</p>
                                            <p className='label'>číslo bank. účtu</p> <p className='data'>{props.user.bank_account}</p>
                                            <p className='label'>rodné číslo</p> <p className='data'>{props.user.birth_number}</p>
                                            <p className='label'>IČO</p> <p className='data'>{props.user.ico}</p>
                                            <p className='label'>DIČ</p> <p className='data'>{props.user.dic}</p>
                                        </div>
                                        <br/>
                                        <h2>Adresa trvalého bydliště</h2>
                                        {userAccountData.address !== null && userAccountData.address.street !== "" ?
                                            <div className='info_grid'>
                                                <p className='label'>Ulice a č.p.</p> <p className='data'>{userAccountData.address.street}</p>
                                                <p className='label'>Město</p> <p className='data'>{userAccountData.address.city}</p>
                                                <p className='label'>PSČ</p> <p className='data'>{userAccountData.address.psc}</p>
                                                <p className='label'>Stát</p> <p className='data'>{userAccountData.address.state}</p>
                                            </div>
                                            : <p>nenalezena</p>
                                        }
                                        <br/>
                                        <h2>Občanský průkaz</h2>
                                        {userAccountData.userIdCard !== null && userAccountData.userIdCard.number !== "" ?
                                            <div className='info_grid'>
                                                <p className='label'>Číslo průkazu</p> <p className='data'>{userAccountData.userIdCard.number}</p>
                                                <p className='label'>Platnost průkazu</p> <p className='data'>{userAccountData.userIdCard.valid}</p>
                                                <p className='label'>průkaz vydal</p> <p className='data'>{userAccountData.userIdCard.provider}</p>
                                            </div>
                                            : <p>nenalezen</p>
                                        }
                                        <br/>
                                        <h2>Řidičský průkaz / Karta pojištěnce</h2>
                                        {userAccountData.userDrivingLicense !== null && userAccountData.userDrivingLicense.number !== "" ?
                                            <div className='info_grid'>
                                                <p className='label'>Číslo průkazu</p> <p className='data'>{userAccountData.userDrivingLicense.number}</p>
                                                <p className='label'>Platnost průkazu</p> <p className='data'>{userAccountData.userDrivingLicense.valid}</p>
                                                <p className='label'>průkaz vydal</p> <p className='data'>{userAccountData.userDrivingLicense.provider}</p>
                                            </div>
                                        : <p>nenalezen</p>
                                        }
                                    </div>
                                }

                                {/* // TODO add change parent user and remove user  */}

                                <div className="container_wrapper paren_user">
                                    <div className='container content_box'>
                                        <div className="action_context_box_wrpaper">
                                            <p className="label">nadřazená osoba</p>

                                            <div className='parent_grid'>
                                                <div className='user_info'>
                                                    {parentInfo.profile_picture !== '' && parentInfo.profile_picture ?
                                                        <img src={pageApiUrl + 'uploads/' + parentInfo.profile_picture} className="profile_img" alt="user_profile" /> :
                                                        <img src={profile_pic} className="profile_img" alt="user_profile" />
                                                    }
                                                    <p>{parentInfo.name + " " + parentInfo.surname}</p>
                                                </div>

                                                <div className='btn_wrapper'>
                                                    <button className="button button--small change_parent_btn" onClick={() => { setShowChangeParentDialog(true) }}>Změnit</button> {/* // TODO onclick change parent */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container_wrapper remove_user">
                                    <div className='container content_box'>
                                        <div className="action_context_box_wrpaper">
                                            <p className="label">odstranit uživatele</p>

                                            <div className='btn_wrapper'>
                                                <button className="button button--small button--red remove_user_btn" onClick={() => { setShowRemoveUserDialog(true) }}>Odstranit</button> {/* // TODO onclick change parent */}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>

                        

                    </div>
                </div>
            </div>
        </>)
    } else if (actualPage === 1){
        return (<>
            <div id="user_detail">
                <div className="close_bnt" onClick={props.close}></div>

                <div className="centered-box scroll_box">

                    <div className="container content_box component_cb cb-large user_detail">
                        <div className="fullpage_component_headline">
                            <h3 className="cb_fullpage_label_right">Detail klienta</h3>
                            <p className="user_status">Status <span className={`user_status_inner ${userStatus.class}`}>{userStatus.status}</span></p>
                        </div>

                        <div className="page_switch_wrapper">
                            <div className="page pg1" onClick={() => setActualPage(0)}>
                                <p>Základní informace</p>
                            </div>
                            <div className="page pg2 active_page" onClick={() => setActualPage(1)}>
                                <p>Investice a Smlouvy</p>
                            </div>
                        </div>

                        <div className='user_detail_grid'>
                            <div className='user_detail_grid_left_side'>

                                <div className='user_info_page2'>
                                    {userAccountData.userProfile !== '' && userAccountData.userProfile ?
                                        <img src={pageApiUrl + 'uploads/' + userAccountData.userProfile} className="profile_img" alt="user_profile" /> :
                                        <img src={profile_pic} className="profile_img" alt="user_profile" />
                                    }
                                    <p>{userAccountData.name}</p>
                                </div>

                                <div className="container content_box component_cb grid_box">
                                    <p className="cb_label_left">Investice</p>
                                    <UserInvestmentOverview userId={userAccountData.id} />
                                </div>



                            </div>
                            <div className='user_detail_grid_right_side'>
                                <div className="container content_box component_cb grid_box">
                                    <p className="cb_label_left">Smlouvy</p>
                                    <UserDocuments userId={userAccountData.id} />
                                </div>
                            </div>

                        </div>



                    </div>
                </div>
            </div>
        </>)
    } else {
        return (<>
            <Error404 />
        </>)
    }
}

export default UserDetail


