/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import "./css/LogDetail.css"
import { MainContext } from '../contexts/MainContext'
import profile_pic from '../img/profile_pic.svg'

function LogDetail(props) {
    const { getError, pageApiUrl, getTableWhereApi } = useContext(MainContext)
    
    useEffect(() => {
        if (props.log && props.user){
            getUserData()
            console.log(props.log)
            console.log(props.user)
        }
    }, [props.log, props.user])

    const handleClose = () => {
        props.close()
    }

    const [user, setUser] = useState(null)

    const getUserData = async () => {
        let reqUsersData = await getTableWhereApi('users', 'id', props.user.id, 'int')
        if (!Array.isArray(reqUsersData) || reqUsersData.length === 0) {
            getError('Nastala chyba načítání dat ' + reqUsersData)
            return
        }
        setUser(reqUsersData[0])

    }


    return(<>
        <div id="log_detail">
            <div className="close_bnt" onClick={handleClose}></div>

            <div className="centered-box">
                
                <div className="container content_box component_cb cb-mid log_box">
                    <p className="cb_label_right_large">Detail akce</p>
                    <p className='date'>{props.log.timestemp}</p>

                    {user && props.log &&
                       <div className='grid_box'>
                        <div className='user_info_box'>
                            <div className='user_info_page'>
                                {props.user.profile_picture !== '' && props.user.profile_picture ?
                                    <img src={pageApiUrl + 'uploads/' + props.user.profile_picture} className="profile_img" alt="user_profile" /> :
                                    <img src={profile_pic} className="profile_img" alt="user_profile" />
                                }
                                <p>{props.user.name + " " + props.user.surname}</p>
                            </div>

                            <br />
                            <br />
                            <br />

                            <p>
                                id: {user.id} <br/>
                                oprávnění: {user.user_type} <br/>
                                note: {props.log.note} <br/>
                                datum: {props.log.timestemp}
                            </p>

                        </div>

                        <div className='log_detail_part'>
                            <h3>
                                {props.log.title}
                            </h3>
                            <p className='label'>SQL logs</p>
                            <p>
                                {props.log.content}
                            </p>
                        </div>
                   </div>
                   }
                    
                    
                </div>
            </div>
        </div>
    </>)
}

export default LogDetail


