/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react"
import { MainContext } from '../contexts/MainContext'
import './css/Logs.css'
import LogDetail from "./LogDetail"

function Logs() {
    const { userData, getTableApi, GetError } = useContext(MainContext)
    const [logData, setLogData] = useState([])
    const [allUserData, setAllUserData] = useState([])
    const [showDetail, setShowDetail] = useState(false)
    const [selectedLog, setSelectedLog] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
   
    useEffect(() => {
        if (userData.loaded !== false) {
            getLogData()
        }
    }, [userData])

    const getLogData = async () => {
        let reqLogData = await getTableApi('logs')

        if (!Array.isArray(reqLogData)) {
            GetError("Nastala chyba načítání")
            return
        }

        reqLogData = reqLogData.reverse()

        let reqUserData = await getTableApi('user_data')

        if (!Array.isArray(reqUserData)) {
            GetError("Nastala chyba načítání")
            return
        }

        setLogData(reqLogData)
        setAllUserData(reqUserData)
    }

    const handleShowLog = (log, user) => {
        console.log("show", log)
        setSelectedLog(log)
        setSelectedUser(user)
        setShowDetail(true)
    }

    const handleClose = () => {
        setShowDetail(false)
    }

    if(showDetail){
        return(
            <LogDetail log={selectedLog} user={selectedUser} close={handleClose} />
        )
    }

  
    return (<>
        <div className="fullpage_component_wapper">
            <div className="container content_box component_cb_fullpage logs_component">
                <div className="fullpage_component_headline">
                    <h3 className="cb_fullpage_label_right">Historie operací</h3>
                    <p className="cb_fullpage_sublabel_right">Systémové logy SQL</p>
                </div>

                <div className="cl_header_grid">
                    <div className="grid_item">
                        {/* <p className="legend">{"<< search >>"}</p> */}
                        <br/>
                    </div>
                </div>

                <div className="main_content_box">
                    <div className="cl_line line_legend">
                        <p>id</p>
                        <p>user id</p>
                        <p>uživatel</p>
                        <p>akce</p>
                        <p>datum</p>
                    </div>

                    <div className="cl_line_box_wrapper">
                        {Array.isArray(logData) && logData.length !== 0 && Array.isArray(allUserData) && allUserData.length !== 0 ? logData.map((data, i) => {
                            let user = allUserData.find(user => user.id === data.user_id)
                            return (
                                <div className='cl_line_box' key={i} onClick={() => { handleShowLog(data, user)}} >
                                    <p>{data.id}</p>
                                    <p>{data.user_id}</p>
                                    <p>{user.name + " " + user.surname}</p>
                                    <p>{data.title}</p>
                                    <p>{data.timestemp}</p>
                                </div>
                            )


                        }) : <p className="empty_label">Nebyly nalezeny žádné data.</p>}

                        <br />
                        <br />
                    </div>
                </div>


            </div>

            <br />
            <br />
        </div>

    </>)
}

export default Logs