import React, { useContext, useState } from 'react'
import { MainContext } from '../contexts/MainContext'
import '../css/app.css'
import './css/Login.css'

function Login(props) {

    const { loginUser, isLoggedIn } = useContext(MainContext);
    const initialState = {
        userInfo: {
            name: '',
            password: '',
        },
        errorMsg: '',
        successMsg: '',
    }
    const [state, setState] = useState(initialState);

    // On change input value (email & password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo: {
                ...state.userInfo,
                [e.target.name]: e.target.value
            }
        });
    }

    // On Submit Login From
    const submitForm = async (event) => {
        event.preventDefault();
        const data = await loginUser(state.userInfo);

        console.log(data)

        if (data.success && data.token) {
            setState({
                ...initialState,
                successMsg: 'Sucess'
            });
            localStorage.setItem('loginToken', data.token);
            await isLoggedIn();
        }
        else {
            setState({
                ...state,
                successMsg: '',
                errorMsg: data.message
            });
            console.error("[ error ] login")

        }
    }

    const handleForgotenPassword = () => {
        alert("Tuto funkci pro Vás připravujeme, zatím prosím zašlete požadavek s obnovením hesla na info@futureinvest.cz")
        // TODO -> finish function
    }

    let successMsg = '';
    let errorMsg = '';
    if (state.errorMsg) {
        errorMsg = <div className="notification notification--error">{state.errorMsg}</div>;
    }
    if (state.successMsg) {
        successMsg = <div className="notification notification--success">{state.successMsg}</div>;
    }

    let login_page_bg = ''
    if(props.login_bg) {
        login_page_bg = 'login_page'
    }

    return (
        <div className={`_loginRegister ${login_page_bg}`}>

            <div className="v-spacing-small"></div>

            <div className="container content_box cb_login_form cb-small-dialog">
                <h1 className="text-center">Přihlášení</h1>
                <br />
                <form onSubmit={submitForm} noValidate className="login_form">
                    <div className="form-control">
                        {/* <label className="text-gray">Login</label> */}
                        <div className="input input-fullWidth">
                            <input placeholder="Login" name="name" className="input" type="text" required value={state.userInfo.name} onChange={onChangeValue} />
                        </div>
                    </div>
                    <div className="form-control">
                        {/* <label className="text-gray">Heslo</label> */}
                        <div className="input input-fullWidth">
                            <input placeholder="Heslo" name="password" className="input" type="password" required value={state.userInfo.password} onChange={onChangeValue} />
                        </div>
                    </div>

                    <p onClick={handleForgotenPassword} className="login_label_small">Zapomenuté heslo?</p>

                    <button type="submit" className="button button--small login_btn">Přihlásit se</button>
                    
                </form>

            </div>

            <div className="login_logo"></div>
        

        {errorMsg}
        {successMsg}

            {login_page_bg ? <h3 className="copyright"> 
            <span className="earc_logo">
                <svg xmlns="http://www.w3.org/2000/svg" width="28.026" height="24.167" viewBox="0 0 28.026 24.167">
                    <g id="Vrstva_2" transform="translate(-124 -232.3)">
                        <path id="Path_569" data-name="Path 569" d="M282.823,232.3,271.1,252.285l4.514-.746,10.5-13.658Z" transform="translate(-144.857 0)" fill="rgba(47,62,104,0.65)" />
                        <path id="Path_570" data-name="Path 570" d="M626.948,671.2l-3.376,5.768,4.666,5.259L616.6,684.263h18.085Z" transform="translate(-485.089 -432.207)" fill="rgba(47,62,104,0.65)" />
                        <path id="Path_571" data-name="Path 571" d="M125.374,1599.931l4.478-.831H142.56l9.466,3.326H124Z" transform="translate(0 -1345.958)" fill="rgba(47,62,104,0.65)" />
                    </g>
                </svg>
            </span> Powered by earc 2021 v1.12 (beta)</h3> : ''}

        </div>
    );
}

export default Login;