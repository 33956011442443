/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import "./css/InvestmentDetail.css"
import { MainContext } from '../contexts/MainContext'
import profile_pic from '../img/profile_pic.svg'
import Loading from './Loading'


function InvestmentDetail(props) {
    const { getTableApi, getError, pageApiUrl, userData, getTableWhereApi, convertTimestamp, postTableApi, getSuccess } = useContext(MainContext)

    const [invData, setInvData] = useState(null)

    useEffect(() => {
        if (userData) {
            getInvestmentData()

            console.log(props, "<<< props")
        }
    }, [userData])

    const getInvestmentData = async () => {
        const requestInvestmentData = await getTableWhereApi('investments', 'id', props.invId, 'int')
        if (!Array.isArray(requestInvestmentData) || requestInvestmentData.length === 0) {
            getError("Nastala chyba: " + requestInvestmentData)
            return
        }
        let invDataResp = requestInvestmentData[0]

        const requestUserData = await getTableWhereApi('user_data', 'id', invDataResp.user_id, 'int')
        if (!Array.isArray(requestUserData) || requestUserData.length === 0) {
            getError("Nastala chyba: " + requestUserData)
            return
        }

        console.log(requestUserData, "<<< requestUserData")
        console.log(requestInvestmentData, "<<< requestInvestmentData")

        invDataResp.user_name = requestUserData[0].name + " " + requestUserData[0].surname

        setInvData(invDataResp)

        console.log(">> inv ", requestInvestmentData[0])
    }

    const handleClose = () => {
        props.closeCallBack()
    }

    if (!invData) {
        return (<>
            <Loading />
        </>)
    }

    return (<>
        <div id="investment_detail">
            <div className="close_bnt" onClick={handleClose}></div>

            <div className="centered-box">

                <div className="container content_box component_cb cb-mid manual_account_manager">
                    <p className="cb_label_right">Investice</p>

                    <div className="invDetailContainer">

                        <p className="invDetailLabel">id</p>
                        <p className="invDetailValue">{invData.id}</p>

                        <p className="invDetailLabel">typ investice</p>
                        <p className="invDetailValue">{parseInt(invData.investment_type) === 1 ? "4 roky" : "2 roky"}</p>

                        <p className="invDetailLabel">id uživatele</p>
                        <p className="invDetailValue">{invData.user_id}</p>

                        <p className="invDetailLabel">uživatel</p>
                        <p className="invDetailValue">{invData.user_name}</p>

                        <p className="invDetailLabel">úrok</p>
                        <p className="invDetailValue">{invData.interest} %</p>

                        <p className="invDetailLabel">částka</p>
                        <p className="invDetailValue">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(invData.amount)}</p>

                        <p className="invDetailLabel">délka (roky)</p>
                        <p className="invDetailValue">{invData.length}</p>

                        <p className="invDetailLabel">identita</p>
                        <p className="invDetailValue">{invData.identity}</p>

                        <p className="invDetailLabel">datum začátek</p>
                        <p className="invDetailValue">{invData.start_date?.split("_")[0]}</p>

                        <p className="invDetailLabel">datum konec</p>
                        <p className="invDetailValue">{invData.end_date?.split("_")[0]}</p>


                    </div>

                </div>
            </div>
        </div>
    </>)
}

export default InvestmentDetail


