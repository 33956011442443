/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { MainContext } from '../contexts/MainContext'
import "./css/ChangeParent.css"


function ChangeParent(props) {
    const { getError, getTableApi, getSuccess, postTableApi, getTableWhereApi } = useContext(MainContext)

    const [allUsersData, setAllUsersData] = useState(false)
    const [selectUser, setSelectUser] = useState(null)

    useEffect(() => {
        getUsers()
        console.log(props.user)

    }, [])

    const getUsers = async () => {
        let reqAllUser = await getTableApi('user_data')
        if (!Array.isArray(reqAllUser) || reqAllUser.length === 0) {
            getError("Nastala chyba: " + reqAllUser)
            return
        }
        reqAllUser = reqAllUser.filter(data => (data.id >= 10002 && data.id !== 10003) )
        setAllUsersData(reqAllUser)

    }

    const handleConfirm = async () => {
        if (selectUser === null){
            getError("Nebyl vybránu žádný uživatel")
            return
        }

        let reqUserData = await getTableWhereApi('user_data', 'id', props.user.id, 'int')
        if (!Array.isArray(reqUserData) || reqUserData.length === 0) {
            getError("Nastala chyba: " + reqUserData)
            return
        }

        let getUserData = reqUserData[0]
        getUserData.parent = selectUser

        let userPostData = {
            data: [getUserData],
            changes: [0],
            post_table: 'user_data'
        }
        let respUpdate = await postTableApi(userPostData)

        if (!respUpdate.hasOwnProperty('success') || respUpdate.success !== 1) {
            getError("Nastala chyba: " + respUpdate)
            return
        }

        getSuccess("Úspěšně změněno")
        props.close()
    }

    return (<>
        <div id="change_parent">
            <div className="centered-box">
                <div className="container content_box change_parent_box ">

                    <div className="confirm_wrapper">
                        <h3 className="text-center change_parent_text">Změnit nadřazenou osobu</h3>

                        <div className='select_user_wrapper'>
                            <div className='select_user_grid'>
                                <div className="itw_line line_legend">
                                    <p>id</p>
                                    <p>jméno</p>
                                </div>

                                {Array.isArray(allUsersData) && allUsersData.length !== 0 ? allUsersData.map((data, i) => {

                                    return (<>
                                        <div className={ data.id === selectUser ? 'inv_line_box selected' : 'inv_line_box'} key={i} onClick={() => { setSelectUser(data.id) }}>
                                            <p>{data.id}</p>
                                            <p>{data.name + " " + data.surname}</p>
                                        </div>
                                    </>)

                                }) : <p className="empty_label">Nebyly nalezeni žádní uživatelé.</p>}
                            </div>
                        </div>

                        <div className="center_buttons_grid">
                            <button className="button button--small button--outlined" onClick={props.close} >
                                zrušit
                            </button>
                            <button className="button button--small" onClick={handleConfirm} >
                                Ano
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>)
}

export default ChangeParent