/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import "./css/ManualAccountManager.css"
import { CircleProgress } from 'react-gradient-progress'
import { MainContext } from '../contexts/MainContext'
import profile_pic from '../img/profile_pic.svg'
import ConfirmDialog from './ConfirmDialog'
import Loading from './Loading'


function ManualAccountManager(props) {
    const { getTableApi, getError, pageApiUrl, getTimestemp, getTableWhereApi, insertTableApi, postTableApi, getSuccess } = useContext(MainContext)

    const [userAccounts, setUserAccounts] = useState([])
    const [showLoading, setShowLoading] = useState(false)
    const [userAccountData, setUserAccountData] = useState({
        id: null,
        name: "",
        accountAmount: 0,
        accountAmountLocked: 0,
        userProfile: "",
        grInvested: 0,
        grAvaliable: 0,
    })

    const [editAmount, setEditAmount] = useState({
        add: 0,
        substract: 0
    })

    const [showComfirmDialog, setShowComfirmDialog] = useState(null)

    useEffect(() => {
        getAccountsData()
    }, [showLoading])

    useEffect(() => {
        updateUserContent()
    }, [userAccounts])

    const updateUserContent = async () => {
        if (userAccountData.id !== null){
            let getUserData = userAccounts.find(user => user.id === userAccountData.id)
            selectAccount(getUserData)
        }
    }

    const getAccountsData = async () => {
        let reqUserAccounts = await getTableApi('user_accounts')
        let reqUserData = await getTableApi('user_data')

        if ((reqUserAccounts && reqUserAccounts.hasOwnProperty('success') && reqUserAccounts.success === 0) || !Array.isArray(reqUserAccounts)) {
            getError("Nastala chyba: getAccountsData() " + reqUserAccounts)
            return
        }

        if ((reqUserData && reqUserData.hasOwnProperty('success') && reqUserData.success === 0) || !Array.isArray(reqUserData)) {
            getError("Nastala chyba: getAccountsData() " + reqUserData)
            return
        }

        let userDataArray = []
        reqUserAccounts.forEach((item, i) => {
            let user = reqUserData.find(user => user.id === item.id)
            if (!user){
                console.error(">> user_data X accounts inconsistency !! ", item.id)
                return
            }
            
            let graphValueInvested = 0
            let graphValueAvaliable = 0

            if (item.amount != 0){
                graphValueInvested = Math.round(item.locked_amount / item.amount * 100)
                graphValueAvaliable = 100 - Math.round(item.locked_amount / item.amount * 100)
            }

            let userAccountRecord = {
                id: item.id,
                name: user.name + " " + user.surname,
                accountAmount: item.amount, // todo
                accountAmountLocked: item.locked_amount,
                userProfile: user.profile_picture,
                grInvested: graphValueInvested,
                grAvaliable: graphValueAvaliable,

            }
            userDataArray.push(userAccountRecord)
        })
        setUserAccounts(userDataArray)
        
    }

    const selectAccount = (account) => {
        setUserAccountData(account)
    }

    const handleClose = () => {
        props.closeCallBack()
    }

    const updateContent = (e, key) => {
        let value = editAmount[key]
        value = e.target.value

        if (e.target.value === "") {
            value = 0
        }

        if (!Number(e.target.value) && e.target.value !== "") {
            value = 0
            return
        }
        setEditAmount({
            ...editAmount,
            [key]: parseInt(value)
        })
    }

    const handleCloseConfirm = () => {
        setShowComfirmDialog(null)
    }

    const handleAccoutEditAdd = async () => {
        setShowComfirmDialog(null)
        setShowLoading(true)

        let userAccount = await getTableWhereApi('user_accounts', 'id', userAccountData.id, 'int')
        if (!Array.isArray(userAccount) || userAccount.length === 0) {
            getError("Nastala chyba: " + userAccount)
            setShowLoading(false)
            return
        }
        userAccount = userAccount[0]

        let moveProcentage = 100 // round to 2 deciamls
        if (userAccount.amount != 0){
            moveProcentage = Math.round(((editAmount.add / parseInt(userAccount.amount)) * 100) * 100) / 100
        }
        let transactionObj = {
            data: {
                id: null,
                user_id: userAccountData.id,
                amount: editAmount.add,
                acount_move: moveProcentage,
                type: 1,
                description: 'Výnos z investice',
                timestemp: getTimestemp()
            },
            post_table: 'transactions'
        }

        let insertResp = await insertTableApi(transactionObj)

        if (!insertResp.hasOwnProperty('success') || insertResp.success !== 1) {
            getError("Nastala chyba: " + insertResp)
            setShowLoading(false)
            return
        }

        let editedUserAccount = {
            id: userAccount.id,
            amount: parseInt(userAccount.amount) + editAmount.add,
            locked_amount: userAccount.locked_amount
        }
        let accountsPostData = {
            data: [editedUserAccount],
            changes: [0],
            post_table: 'user_accounts'
        }

        let postResp = await postTableApi(accountsPostData)
        if (!postResp.hasOwnProperty('success') || postResp.success !== 1) {
            getError("Nastala chyba: " + postResp)
            setShowLoading(false)
            return
        }
        setShowLoading(false)
        getSuccess("Operace proběhla úspěšně")
        setEditAmount({
            add: 0,
            substract: 0
        })
    }

    const handleAccoutEditSubstract = async () => {
        setShowComfirmDialog(null)
        setShowLoading(true)

        let userAccount = await getTableWhereApi('user_accounts', 'id', userAccountData.id, 'int')
        if (!Array.isArray(userAccount) || userAccount.length === 0) {
            getError("Nastala chyba: " + userAccount)
            setShowLoading(false)
            return
        }
        userAccount = userAccount[0]


        if (parseInt(userAccount.amount) < editAmount.substract) {
            getError("Částka pro odebrání je větší než stav účtu " + parseInt(userAccount.amount) + " < " + editAmount.substract)
            setShowLoading(false)
            return
        }


        let moveProcentage = 0 // round to 2 deciamls
        if (userAccount.amount != 0) {
            moveProcentage = Math.round(((editAmount.substract / parseInt(userAccount.amount)) * 100) * 100) / 100
        }
        let transactionObj = {
            data: {
                id: null,
                user_id: userAccountData.id,
                amount: 0 - editAmount.substract,
                acount_move: 0 - moveProcentage,
                type: 2,
                description: 'Výnos z investice',
                timestemp: getTimestemp()
            },
            post_table: 'transactions'
        }

        let insertResp = await insertTableApi(transactionObj)

        if (!insertResp.hasOwnProperty('success') || insertResp.success !== 1) {
            getError("Nastala chyba: " + insertResp)
            setShowLoading(false)
            return
        }

        let editedUserAccount = {
            id: userAccount.id,
            amount: parseInt(userAccount.amount) - editAmount.substract,
            locked_amount: userAccount.locked_amount
        }
        let accountsPostData = {
            data: [editedUserAccount],
            changes: [0],
            post_table: 'user_accounts'
        }

        let postResp = await postTableApi(accountsPostData)
        if (!postResp.hasOwnProperty('success') || postResp.success !== 1) {
            getError("Nastala chyba: " + postResp)
            setShowLoading(false)
            return
        }
        setShowLoading(false)
        getSuccess("Operace proběhla úspěšně")
        setEditAmount({
            add: 0,
            substract: 0
        })
    }

    if (showComfirmDialog === 'add') {
        return (<>
            <ConfirmDialog confirm={handleAccoutEditAdd} close={handleCloseConfirm} text={"Chcete připsat částku " + new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(editAmount.add)  + " na účet " + userAccountData.id + " " + userAccountData.name + "?"} />
        </>)
    }

    if (showComfirmDialog === 'substract') {
        return (<>
            <ConfirmDialog confirm={handleAccoutEditSubstract} close={handleCloseConfirm} text={"Chcete oedbrat částku " + new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(editAmount.substract) + " z účtu " + userAccountData.id + " " + userAccountData.name +  "?"}  />
        </>)
    }

    if (showLoading) {
        return (<>
            <Loading />
        </>)
    }

    return(<>
        <div id="manual_account_manager">
            <div className="close_bnt" onClick={handleClose}></div>

            <div className="centered-box">
                
                <div className="container content_box component_cb cb-mid manual_account_manager">
                    <p className="cb_label_right">Manuální editace klientských účtů</p>


                    <div className='ac_manager_grid'>
                        <div className='ac_grid_accounts'>
                            <div className='list_wrapper'>

                                <div className="acm_line line_legend">
                                    <p>id</p>
                                    <p>jméno</p>
                                    <p>investováno</p>
                                    <p>zůstatek</p>
                                    <p>v inv.</p>
                                </div>
                                <div className='acm_line_box_wrapper'>
                                    {Array.isArray(userAccounts) && userAccounts.map((data, i) => {

                                        let progressPerc = 0
                                        if (data.accountAmount != 0){
                                            progressPerc = Math.round(data.accountAmountLocked * 100 / data.accountAmount)
                                        }
                                        return (
                                            <div className='acm_line_box' key={i} onClick={() => { selectAccount(data) }} >
                                                <p>{data.id}</p>
                                                <p>{data.name}</p>
                                                <p className="currency">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(data.accountAmountLocked)}</p>
                                                <p className="currency">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(data.accountAmount)}</p>
                                                <div className="circular_progressbar_small inv_overview_circ">
                                                <CircleProgress percentage={progressPerc} primaryColor={['#71E7FF', '#A95BFD']} strokeWidth={2.5} fontFamily='Roboto Mono' width={41} fontSize='9px' fontColor='#2F3E68' secondaryColor='#EEEEEE' />
                                            </div>
                                            </div>
                                        )

                                    })}
                                </div>
                            </div>
                        </div>
                        <div className='ac_grid_actions_wrapper'>
                            {userAccountData.id !== null && 
                            <>
                                <div className='ac_grid_account_info'>

                                    <div className="container content_box component_cb cb-small acm_account_detail">

                                        <div className='user_info'>
                                            {userAccountData.userProfile !== '' && userAccountData.userProfile ?
                                                <img src={pageApiUrl + 'uploads/' + userAccountData.userProfile} className="profile_img" alt="user_profile" /> :
                                                <img src={profile_pic} className="profile_img" alt="user_profile" />
                                            }
                                            <p>{userAccountData.name}</p>
                                        </div>


                                        <h2 className="account_balance">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(userAccountData.accountAmount)}</h2>

                                        <div className="po_grid">

                                            <div className="circular_progressbar_box">
                                                <div className="circular_progressbar">
                                                    {window.innerWidth < 1400 ?
                                                        <CircleProgress percentage={userAccountData.grInvested} primaryColor={['#71E7FF', '#00BAFF']} strokeWidth={3} width={60} fontColor='#2F3E68' secondaryColor='#EEEEEE' />
                                                        : <CircleProgress percentage={userAccountData.grInvested} primaryColor={['#71E7FF', '#00BAFF']} strokeWidth={4} width={70} fontColor='#2F3E68' secondaryColor='#EEEEEE' />
                                                    }
                                                </div>
                                                <div className="textbox">
                                                    <p className="amount">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(userAccountData.accountAmountLocked)}</p>
                                                    <p className="amount_label">v investicích</p>
                                                </div>
                                            </div>

                                            <div className="circular_progressbar_box">
                                                <div className="circular_progressbar">
                                                    {window.innerWidth < 1400 ?
                                                        <CircleProgress percentage={userAccountData.grAvaliable} primaryColor={['#6404CB', '#A95BFD']} strokeWidth={3} width={60} fontColor='#2F3E68' secondaryColor='#EEEEEE' />
                                                        : <CircleProgress percentage={userAccountData.grAvaliable} primaryColor={['#6404CB', '#A95BFD']} strokeWidth={4} width={70} fontColor='#2F3E68' secondaryColor='#EEEEEE' />
                                                    }
                                                </div>
                                                <div className="textbox">
                                                    <p className="amount">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(userAccountData.accountAmount - userAccountData.accountAmountLocked)}</p>
                                                    <p className="amount_label">k dispozici</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className='add_amount_box'>
                                    <div className="container content_box component_cb cb-small acm_account_edit">
                                        <div className="account_edit_item">
                                            <label className="input_label">Připsat částku</label>
                                            <div className="input curency">
                                                <input type="text" value={editAmount.add} placeholder="částka Kč" onChange={e => updateContent(e, 'add')} />
                                            </div>
                                            <button className="button button--small edit_account_btn" onClick={() => { setShowComfirmDialog('add') }} >
                                                přidat
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='substract_amount_box'>
                                    <div className="container content_box component_cb cb-small acm_account_edit">
                                        <div className="account_edit_item">
                                            <label className="input_label">Odebrat částku</label>
                                            <div className="input curency">
                                                <input type="text" value={editAmount.substract} placeholder="částka Kč" onChange={e => updateContent(e, 'substract')} />
                                            </div>
                                            <button className="button button--small button--outlined edit_account_btn" onClick={() => { setShowComfirmDialog('substract') }} >
                                                odebrat
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                            }
                        </div>
                    </div>

                    

                </div>
            </div>
        </div>
    </>)
}

export default ManualAccountManager


