/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react"
import {Link} from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { MainContext } from '../contexts/MainContext'
import "./css/Navbar.css";
import profile_pic from '../img/profile_pic.svg'

import Notification from './Notifications'
import Loading from "./Loading";

function Navbar() {
    const { rootState, logoutUser, actualPage, userData, notifData, checkNotifications, systemNotif, pageApiUrl, overlayContent } = useContext(MainContext);
    const { theUser, isAuth } = rootState;
    let navigate = useNavigate();

    const [showNotifications, setShowNotifications] = useState(false)
    const [loading, setLoading] = useState(true)

    const handelLogoutUser = () => {
        logoutUser();
        navigate('/')
    }

    useEffect(() => {       // wait for userData to be loaded than run function
        setTimeout(() => {
            setLoading(false)
        }, 600)
    }, [userData])

    const handelNotificationsBox = () => {
        setShowNotifications(showNotifications => !showNotifications)
        checkNotifications(userData)
    }

    if (!isAuth){
        return ('')
    }

    const userPermisions = []
    const userPermisionsCatalog = [
        <Link to="/" key='0'><div className={`nav_panel_link link_0 ${actualPage === '/' ? 'active' : ''}`} id='link_0'></div></Link>,
        <Link to="/investments" key='1'><div className={`nav_panel_link link_1 ${actualPage === 'investments' ? 'active' : ''}`} id='link_1'></div></Link>,
        <Link to="/clients" key='2'><div className={`nav_panel_link link_2 ${actualPage === 'clients' ? 'active' : ''}`} id='link_2'></div></Link>,
        <Link to="/network" key='7'><div className={`nav_panel_link link_8 ${actualPage === 'network' ? 'active' : ''}`} id='link_8'></div></Link>,
        <Link to="/timeline" key='8'><div className={`nav_panel_link link_9 ${actualPage === 'timeline' ? 'active' : ''}`} id='link_9'></div></Link>,
        <Link to="/docs" key='3'><div className={`nav_panel_link link_3 ${actualPage === 'docs' ? 'active' : ''}`} id='link_3'></div></Link>,
        <Link to="/texts" key='6'><div className={`nav_panel_link link_7 ${actualPage === 'texts' ? 'active' : ''}`} id='link_7'></div></Link>,
        <Link to="/settings" key='4'><div className={`nav_panel_link link_4 ${actualPage === 'settings' ? 'active' : ''}`} id='link_4'></div></Link>,
        <Link to="/logs" key='5'><div className={`nav_panel_link link_5 ${actualPage === 'logs' ? 'active' : ''}`} id='link_5'></div></Link>
    ]

    if (theUser != null){
        // user user_type 'user' does not have acces to clients page others do
        let getPermissions = theUser.user_type === 'admin' ? "1-1-1-1-1-1-1-1": "0-0-0-0-0-0-0-0"
        getPermissions = getPermissions.split("-")
        for (let i = 0; i < getPermissions.length; i++){
            if (getPermissions[i] === "1"){
                userPermisions[i] = userPermisionsCatalog[i]
            }
        }
    }


    // client side ignores permissions from db type 1-1-0-0-1...

    // if (theUser != null){
    //     let getPermissions = theUser.permissions.split("-");
    //     for (let i = 0; i < getPermissions.length; i++){
    //         if (getPermissions[i] === "1"){
    //             userPermisions[i] = userPermisionsCatalog[i]
    //         }
    //     }
    // }

    if(!userData.name || loading){
        return (<>
            <Loading />
        </>)
    }

    return (
        <>
            <div className="nav_header">
                {/* <h3 className="top_text">earc content management system</h3> */}
                <div className="user_info">
                    { userData.profile_picture !== '' && userData.profile_picture ?
                        <img src={pageApiUrl + 'uploads/' + userData.profile_picture } className="profile_img" alt="user_profile" /> :
                        <img src={profile_pic} className="profile_img" alt="user_profile" />
                    }
                    {/* <img src={profile_pic} className="profile_img" alt="user_profile" /> */}
                    <p>{userData.name + ' ' + userData.surname}</p>
                </div>

                <h2 className="mid_nav_h2">Future Invest Administrace</h2>

                <div className="user_amount_stats_wraper">
                    <div className="user_amount_stats">
                        <div className="user_amount_stats_box">
                            {/* <p className="label">prostředky</p>
                            <p>{ new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0  }).format(userAccountData.amount) }</p> */}
                        </div>
                        <div className="user_amount_stats_box">
                            {/* <p className="label">k dispozici</p>
                            <p>{ new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0  }).format(userAccountData.amount - userAccountData.locked_amount) }</p> */}
                        </div>
                        <div className={`notification_btn ${notifData.newNotif ? 'new_notif' : ''}`} onClick={handelNotificationsBox} ></div>

                        {showNotifications === true && (
                            <>
                                <Notification/>
                                <div className="notif_filter" onClick={handelNotificationsBox}></div>
                            </>
                        )}

                    </div>
                </div>
                <a href="/register"><button className="button button--small invest_nav_btn" >
                    Přidat klienta
                </button></a>
                
                
	        </div>

            <div className="nav_panel">
                <Link to="/"><div className="nav_panel_logo"></div></Link>

                <div className="nav_panel_links">
                    <div className="user_level_ability">
                        { userPermisions }
                    </div>

                    <Link to="/" onClick={handelLogoutUser}><div className="nav_panel_link link_6_logout"></div></Link>
                </div>
            </div>

            <div className="system_notification_box">
                { systemNotif.success }
                { systemNotif.error }
            </div>


            {overlayContent}
        </>
    )
}

export default Navbar
