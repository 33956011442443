/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import "./css/LoadingPayout.css"
import { CircleProgress } from 'react-gradient-progress'


function LoadingPayout(props) {

    // const [loadingOutData, setLoadingOutData] = useState([])

    // useEffect(() => {
    //     if (props.data) {
    //         updateData()
    //     }
    // }, [props.data])

    // const updateData = () => {
    //     setLoadingOutData(props.data)
    // }

    return(<>
        <div id="loading" className={props.type}>
            <div className="centered-box">
                {/* <div className="content">
                    <div className="loader-circle"></div>
                    <div className="loader-line-mask">
                        <div className="loader-line"></div>
                    </div>
                </div> */}

                <div className="container content_box component_cb interest_calc_payout">
                    <p className="cb_label_right">Automatické Vyplácení úroků</p>

                    <p className="data_pay">{props.data}</p>

                    <div className="circular_progressbar loading_payout_cg_prb">
                        <CircleProgress percentage={props.progress} primaryColor={['#71E7FF', '#00BAFF']} strokeWidth={4} width={80} fontColor='#2F3E68' secondaryColor='#EEEEEE' />
                    </div>

                    <div className="content">
                        <div className="loader-circle"></div>
                        <div className="loader-line-mask">
                            <div className="loader-line"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>)
}

export default LoadingPayout