/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react"
import { MainContext } from '../contexts/MainContext'
import Error404 from "../pages/Error404";
import './css/Investments.css'
import { CircleProgress } from 'react-gradient-progress'

function Investments() {
    const { rootState, userData, getTableApi, getError, convertTimestamp } = useContext(MainContext)
    const { theUser } = rootState
    const [clientsData, setClientsData] = useState([])
    const [investmentsData, setInvestmentsData] = useState([])
    const [clientsStats, setClientsStats] = useState({
        count: 0,
        active: 0,
        combineInvested: 0
    })

    useEffect(() => {
        if (userData.loaded !== false) {
            getClientsData()
        }
    }, [userData])

    const getClientsData = async () => {
        let requestClientData = await getTableApi('user_data')
        let requestAccountData = await getTableApi('user_accounts')
        let requestUserData = await getTableApi('users')

        if (!Array.isArray(requestAccountData) || requestAccountData.length === 0 || !Array.isArray(requestUserData) || requestUserData.length === 0) {
            getError("Nastala chyba: requestAccountData OR requestUserData" )
            return
        }

        let requestInvestmentData = await getTableApi('investments')
        if (!Array.isArray(requestInvestmentData) || requestInvestmentData.length === 0) {
            getError("Nastala chyba: " + requestInvestmentData)
            return
        }
        requestInvestmentData = requestInvestmentData.sort((a, b) => convertTimestamp(b.start_date) - convertTimestamp(a.start_date));

        if (Array.isArray(requestClientData)) {
            if (theUser.user_type === 'admin') {
                requestClientData = requestClientData.filter(item => item.id >= 10001)  // filter us 10001, 10003
            } else {
                requestClientData = requestClientData.filter(item => item.id !== userData.id)
                requestClientData = requestClientData.reverse()
            }

            let combineInvested = 0
            let activeInvCount = 0

            requestInvestmentData.forEach((inv) => {
                if (Date.now() <= convertTimestamp(inv.end_date)) {
                    activeInvCount++
                }
            })

            requestClientData.forEach((user, i) => {
                let userAccount = requestAccountData.find(account => account.id === user.id)
                if (userAccount) {
                    combineInvested += parseInt(userAccount.locked_amount)
                }
            })

            setInvestmentsData(requestInvestmentData)
            setClientsData(requestClientData)
            setClientsStats({
                count: requestInvestmentData.length,
                active: activeInvCount,
                combineInvested: combineInvested
            })
        }
    }


    if (userData && userData.loaded !== false && theUser.user_type !== 'admin' && theUser.user_type !== 'dealer'){
        return <Error404 />
    }

    return (<>
        <div className="fullpage_component_wapper">
            <div className="container content_box component_cb_fullpage investments_component">
                <div className="fullpage_component_headline">
                    <h3 className="cb_fullpage_label_right">Investice</h3>
                    <p className="cb_fullpage_sublabel_right">Stav investic vašich klientů</p>
                </div>

                <div className="cl_header_grid">
                    <div className="grid_item">
                        <p className="legend">Celkem investic</p>
                        <p className="value">{clientsStats.count}</p>
                    </div>
                    <div className="grid_item">
                        <p className="legend">Celkové investice</p>
                        <p className="value amount">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(clientsStats.combineInvested)}</p>
                    </div>
                    <div className="grid_item">
                        <p className="legend">Aktivní investice</p>
                        <p className="value">{clientsStats.active + " / " + clientsStats.count}</p>
                    </div>
                    {/* <a className="button button--small reg_button" href="/register">Registrace</a> 
                        // TODO here should by some search box ...
                    */}
                    
                </div>

                <div className="main_content_box">
                    <div className="cl_line line_legend">
                        <p>ID</p>
                        <p>investice</p>
                        <p>uživatel</p>
                        <p>částka</p>
                        <p>úrok</p>
                        <p>první úrok</p>
                        <p>konec</p>
                        <p>progres</p>
                    </div>

                    <div className="cl_line_box_wrapper">
                        {Array.isArray(clientsData) && clientsData.length !== 0 && Array.isArray(investmentsData) && investmentsData.length !== 0 ? investmentsData.map((inv, i) => {
                            let getUserData = clientsData.find(user => user.id === inv.user_id)
                            let progressLength = convertTimestamp(inv.end_date) - convertTimestamp(inv.start_date)
                            let startToNow = Date.now() - convertTimestamp(inv.start_date)
                            let progressPerc = Math.round((startToNow / progressLength) * 100) < 100 ? Math.round((startToNow / progressLength) * 100) : 99
                            
                            return (
                                <div className='cl_line_box' key={i}>
                                    <p>{inv.id}</p>
                                    <p>{parseInt(inv.investment_type) === 1 ? "4 roky" : "2 roky"}</p>
                                    <p>{getUserData.name + " " + getUserData.surname}</p>
                                    <p className="currency">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(inv.amount)}</p>
                                    <p>{inv.interest + "%"}</p>
                                    <p>{inv.start_date.split("_")[0]}</p>
                                    <p>{inv.end_date.split("_")[0]}</p>
                                    
                                    <div className="circular_progressbar_small inv_overview_circ">
                                        <CircleProgress percentage={progressPerc} primaryColor={['#71E7FF', '#A95BFD']} strokeWidth={2.5} fontFamily='Roboto Mono' width={42} fontSize='9px' fontColor='#2F3E68' secondaryColor='#EEEEEE' />
                                    </div>
                                </div>
                            )

                        }) : <p className="empty_label">Nebyly nalezeny žádné investice.</p>}

                        <br />
                        <br />
                    </div>
                </div>


            </div>

            <br />
            <br />
        </div>

    </>)
}

export default Investments