/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react"
import { MainContext } from '../contexts/MainContext'

import './css/Documents.css'
import Loading from "./Loading";


function Documents() {
    const { userData, getTableApi, pageApiUrl, getError, convertTimestamp } = useContext(MainContext);
    const [userDocuments, setUserDocuments] = useState([])

    useEffect(() => {
        if (userData.loaded !== false) {
            getTransactionData()
        }
    }, [userData])

    const getTransactionData = async () => {
        let requestUserDocuments = await getTableApi('user_documents')

        if (!Array.isArray(requestUserDocuments)) {
            getError("Nastala chyba: Načítání dokumentů")
        }

        let reqUserData = await getTableApi('user_data')
        console.log(reqUserData)

        if (!Array.isArray(reqUserData)) {
            getError("Nastala chyba: Načítání dat uživatelů")
        }

        requestUserDocuments.forEach(doc => {
            let getUser = reqUserData.find(item => item.id === doc.user_id)
            if (getUser !== null){
                doc.name = getUser.name + " " + getUser.surname
            }
        })

        requestUserDocuments.sort((a, b) => convertTimestamp(b.timestemp) - convertTimestamp(a.timestemp))

        setUserDocuments(requestUserDocuments)


    }

    const [loading, setLoading] = useState(true)

    useEffect(() => {       // wait for userData to be loaded than run function
        setTimeout(() => {
            setLoading(false)
        }, 400)
    }, [userDocuments])


    if (!userData.name || loading) {
        return (<>
            <Loading type="page"/>
        </>)
    }

    return (<>
        <div className="fullpage_component_wapper">
            <div className="container content_box component_cb_fullpage documents_component">
                <div className="fullpage_component_headline">
                    <h3 className="cb_fullpage_label_right">Smlouvy</h3>
                    <p className="cb_fullpage_sublabel_right">Výpis všech smluv klientů</p>
                </div>

                <div className="tr_header_grid">
                    <div className="grid_item">
                        <p className="legend">Hledat</p>
                       <p>{"<< search box >>"}</p>
                    </div>
                </div>

                <div className="main_content_box">
                    <div className="trd_line line_legend">
                        <p>id investice</p>
                        <p>smlouva</p>
                        <p>uživatel</p>
                        <p>datum začátku</p>
                    </div>

                    <div className="trd_line_box_wrapper">
                        {Array.isArray(userDocuments) && userDocuments.length !== 0 ? userDocuments.map((data, i) => {

                            let timestemp = data.timestemp
                            let getData = data.timestemp !== "" ? timestemp.split("_")[0] : ""

                            return(
                                    <div className='trd_line_box' key={i}>
                                        <p>{data.investment_id}</p>
                                        <p>{'Smlouva investice č. ' + data.investment_id + '/' + data.user_id}</p>
                                        <p>{data.name}</p>
                                        <p>{getData}</p>
                                        <a href={pageApiUrl + 'uploads/' + data.file_name} target="_blank" rel="noreferrer" key={i}>
                                            <div className="download_btn"></div>
                                        </a>
                                    </div>
                            )
                                    
                                
                        }) : <p className="empty_label">Nebyly nalezeny žádné smlouvy.</p>}

                        <br />
                        <br />
                    </div>
            
                    
                </div>

            </div>

            <br />
            <br />
        </div>

    </>)
}

export default Documents