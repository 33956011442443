/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { MainContext } from '../contexts/MainContext'
import './css/PortfolioOverview.css'
import PrevChart from './PrevChart'
import {CircleProgress} from 'react-gradient-progress'

function PortfolioOverview(){
    const { userData, updateUi, getTimestemp, getTableApi, convertTimestamp } = useContext(MainContext);

    const [transactionData, setTransactionData] = useState({
        data: [],
        history: [],
        dates: [] 
    })

    const [accountData, setAccountData] = useState({
        amount: 0,
        amountLocked: 0,
        accountCount: 0,
        loaded: false
    })
    const [investmentData, setInvestmentData] = useState({
        investmentCount: 0,
        newInvestments: 0
    })

    useEffect(() => {       // wait for userData to be loaded than run function
        if(userData.loaded !== false){
            getAccountData()
            getInvestmentData()
        }
    }, [userData, updateUi])

    useEffect(() => {
        if (accountData.loaded !== false){
            getTransactionData()
        }
    }, [accountData])

    const getTransactionData = async () => {
        let requestTransationData = await getTableApi('analytics')
        let valArray = []
        let datesArray = []

        if( requestTransationData && requestTransationData.hasOwnProperty('success') && requestTransationData.success === 0){
            // if has no transaction data return [0,0] -> flat line
            valArray.push(0)
        } else {                    
            requestTransationData.forEach((e) => {
                valArray.push(parseInt(e.complete_amount))
                datesArray.push((e.timestemp).split("_")[0])
            })
            
        }
        datesArray.push(getTimestemp().split("_")[0])
        if(accountData.amount !== 0){
            valArray.push(accountData.amount)
        } else {
            valArray.push(parseInt(requestTransationData[requestTransationData.length - 1].complete_amount))
        }

        setTransactionData({
            data: requestTransationData,
            history: valArray,
            dates: datesArray
        })
    }

    const getAccountData = async () => {
        let requestAccountData = await getTableApi('user_accounts')
        let accountsAmount = 0
        let accountsLockedAmount = 0
        let accountsCount = 0

        if (requestAccountData && requestAccountData.hasOwnProperty('success') && requestAccountData.success === 0){
            return
        }               
        
        requestAccountData.forEach((e) => {
            if(e.id >= 10010){  // filter admins
                accountsAmount += !e.amount ? 0 : parseInt(e.amount)
                accountsLockedAmount += !e.locked_amount ? 0 : parseInt(e.locked_amount)
                accountsCount++
            }
        })
        
        setAccountData({
            amount: accountsAmount,
            amountLocked: accountsLockedAmount,
            accountCount: accountsCount,
            loaded: true
        })
    }


    const getInvestmentData = async () => {
        let investmentData = await getTableApi('investments')
        let activeInvCount = 0
        let newInvCount = 0
        // sort by start_date
        if (Array.isArray(investmentData) && investmentData.length > 1) {
            investmentData.forEach((data) => {
                if (data.user_id >= 10010) {    // ignore admin account up to id 10010

                    var dateBefore = new Date()
                    dateBefore.setDate(dateBefore.getDate() - 30)

                    if (dateBefore < convertTimestamp(data.start_date)) {
                        newInvCount++
                    }
                    if (Date.now() <= convertTimestamp(data.end_date)) {
                        activeInvCount++
                    }
                }
            })
        }

        setInvestmentData({
            investmentCount: activeInvCount,
            newInvestments: newInvCount
        })
    }

    let graphValueInvested = Math.round(accountData.amountLocked / accountData.amount * 100)
    let graphValueAvalible = 100 - Math.round(accountData.amountLocked / accountData.amount * 100)

    if (!accountData.amount){
        graphValueInvested = 0
        graphValueAvalible = 0
    }

    return(
        <div className="container content_box component_cb portfolio_overiview">
            <p className="cb_label_right">Přehled investic</p>

            <div className="portfolio_overiview_conainer">
                <h2 className="acount_balance">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(accountData.amount) }</h2>

                <div className="po_grid">

                    <div className="circular_progressbar_box">
                        <div className="circular_progressbar">
                            <CircleProgress percentage={ graphValueInvested } primaryColor={['#71E7FF', '#00BAFF']} strokeWidth={4} width={80} fontColor='#2F3E68' secondaryColor='#EEEEEE' />
                        </div>
                        <div className="textbox">
                            <p className="amount">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(accountData.amountLocked) }</p>
                            <p className="amount_label">v investicích</p>
                        </div>
                    </div>

                    <div className="circular_progressbar_box">
                        <div className="circular_progressbar">
                            <CircleProgress percentage={ graphValueAvalible } primaryColor={['#6404CB', '#A95BFD']} strokeWidth={4} width={80} fontColor='#2F3E68' secondaryColor='#EEEEEE' />
                        </div>
                        <div className="textbox">
                            <p className="amount">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(accountData.amount - accountData.amountLocked) }</p>
                            <p className="amount_label">volné prostředky</p>
                        </div>
                    </div>

                    <PrevChart data={transactionData}/>

                </div>

                <div className="client_overview_grid">
                    <div className="grid_content_box type1">
                        <h3 className="stat_h3">{accountData.accountCount}</h3>
                        <p className="legend">uživatelů</p>
                    </div>
                    <div className="grid_content_box type2">
                        <h3 className="stat_h3">{investmentData.investmentCount}</h3>
                        <p className="legend">aktivních investic</p>
                    </div>
                    <div className="grid_content_box type3">
                        <h3 className="stat_h3">{investmentData.newInvestments}</h3>
                        <p className="legend">nových investic</p>
                    </div>
                </div>

               



            </div>
        </div>
    )
}

export default PortfolioOverview
