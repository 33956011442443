/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import Login from '../components/Login'
import { MainContext } from '../contexts/MainContext'
import './css/home.css'


import PortfolioOverview from '../components/PortfolioOverview'
import InvestmentOverview from '../components/InvestmentOverview'
import UserRequests from '../components/UserRequests'
import CalculateInterest from '../components/CalculateInterest'
import Error404 from './Error404'

function Home() {
    const { rootState, setActualPage, isLoggedIn, logoutUser } = useContext(MainContext)
    const { isAuth, theUser } = rootState;

    useEffect(() => {       // runs function only once
        setActualPage('/')
        isLoggedIn()
    }, [])

    // If user Logged in
    if (isAuth) {

        // console.log(theUser.type)
        // console.log(theUser)
        if (theUser.user_type !== 'admin'){
            logoutUser();

            return <Error404/>
        }

        return (
            <div className="page_container">

                <div className="components_container_overview">
                    <div className="component_wrap">
                        <PortfolioOverview/>
                    </div>
                    <div className="component_wrap">
                        <InvestmentOverview/>    
                    </div>
                    <div className="component_wrap">
                        <UserRequests/>
                    </div>
                    <div className="component_wrap">
                        <CalculateInterest/>
                    </div>
                </div>

            </div>
        )
    } else {
        return <Login login_bg='true' />
    }
}

export default Home