/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from 'react'
import Timeline from 'react-timelines'
import { MainContext } from '../contexts/MainContext'
import Error404 from "../pages/Error404";
import InvestmentDetail from './InvestmentDetail'
import './css/TimelineChart.css'
import 'react-timelines/lib/css/style.css'

import { START_YEAR, NUM_OF_YEARS } from './services/timelineConstants'
import { buildTimebar, buildInvTrack } from './services/timelineBuilders'

const now = new Date()
const timebar = buildTimebar()

function TimelineChart(props){
    const { rootState, userData, getError, convertTimestamp, getTableApi } = useContext(MainContext)
    const { theUser } = rootState

    const [investmentData, setInvestmentData] = useState(null)
    const [state, setState] = useState({
        open: false,
        zoom: 2,
        tracks: null,
        showInvDetail: false,
        selectedInvId: null
    })

    const clickElement = element => {
        console.log(element);
        // alert(JSON.stringify(element.invData))
        setState({
            ...state,
            showInvDetail: true,
            selectedInvId: element.invData.id
        })
    }

    useEffect(() => {
        console.log("useContextEffect")
        if (userData.loaded !== false && investmentData === null) {
            console.log("if")
            getInvestmentData()
        }
    }, [userData])

    let tracksById = null

    const getInvestmentData = async () => {
        console.log("getInvestmentData")
        let requestInvestmentData = await getTableApi('investments')
        if (!Array.isArray(requestInvestmentData) || requestInvestmentData.length === 0) {
            getError("Nastala chyba: " + requestInvestmentData)
            return
        }
        requestInvestmentData = requestInvestmentData.sort((a, b) => convertTimestamp(a.start_date) - convertTimestamp(b.start_date));
        requestInvestmentData = requestInvestmentData.filter((e) => parseInt(e.user_id) >= 10010) // filter out admins
        console.log("> requestInvestmentData: ", requestInvestmentData)
        setInvestmentData(requestInvestmentData)
        console.log("> investmentData?.length: ", requestInvestmentData?.length)

        // get user data
        const requestUserData = await getTableApi('user_data')
        if (!Array.isArray(requestUserData) || requestUserData.length === 0) {
            getError("Nastala chyba: " + requestUserData)
            return
        }

        tracksById = []
        requestInvestmentData?.forEach((inv, i) => {
            const userData = requestUserData.find(e => e.id === inv.user_id)
            let invData = inv
            invData.userData = userData

            const track = buildInvTrack(i + 1, inv)
            tracksById.push(track)
        });

        setState({
            ...state,
            tracksById,
            tracks: Object.values(tracksById),
        })

        console.log("> tracksById: ", tracksById)
        console.log("> state: ", state)
    }


    console.log('tracksById >>', tracksById)

    const handleToggleOpen = () => {
        setState(({ open }) => ({ open: !open }))
    }

    const handleToggleTrackOpen = track => {
        setState(state => {
            const tracksById = {
                ...state.tracksById,
                [track.id]: {
                    ...track,
                    isOpen: !track.isOpen,
                },
            }

            return {
                tracksById,
                tracks: Object.values(tracksById),
            }
        })
    }

    const { open, zoom, tracks } = state
    const start = new Date(`${START_YEAR}`)
    const end = new Date(`${START_YEAR + NUM_OF_YEARS}`)

    console.log("timebar", timebar)
    console.log("tracks", tracks)

    const handleCloseInvDetail = () => {
        setState({
            ...state,
            showInvDetail: false,
        })
    }

    if (userData && userData.loaded !== false && theUser.user_type !== 'admin' && theUser.user_type !== 'dealer') {
        return <Error404 />
    }




    if (state.showInvDetail) {
        return (<>
            <InvestmentDetail closeCallBack={handleCloseInvDetail} invId={state.selectedInvId} />
        </>)
    }


    return (<>

        <div className="app">
            <h1 className="title">React Timelines</h1>
            {tracks &&

                <Timeline
                    scale={{
                        start,
                        end,
                        zoom,
                    }}
                    isOpen={open}
                    toggleOpen={handleToggleOpen}
                    // zoomIn={this.handleZoomIn}
                    // zoomOut={this.handleZoomOut}
                    clickElement={clickElement}
                    clickTrackButton={track => {
                        alert(JSON.stringify(track))
                        console.log(track)
                    }}
                    timebar={timebar}
                    tracks={tracks}
                    now={now}
                    toggleTrackOpen={handleToggleTrackOpen}
                    enableSticky
                    scrollToNow
                />}
        </div>

        <br />
        <br />
        <br />

        <p>Investment Timeline beta</p>
    </>
    )
    
}

export default TimelineChart
