/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { MainContext } from '../contexts/MainContext'
import { Link } from 'react-router-dom'
import '../css/app.css'
import './css/Register.css'
import Error404 from '../pages/Error404'
import Loading from "./Loading";


function Register(props) {
    const { registerUser, rootState, isLoggedIn, userData, getError } = useContext(MainContext);
    const [isFlaged, setFlaged] = useState(false);
    const { isAuth } = rootState;

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        isLoggedIn()
    }, [])

    const initialState = {
        userInfo: {
            username: '',
            password: '',
            passwordRepeat: '',
            name: '',
            surname: '',
            parent: userData.id
        },
        errorMsg: '',
        successMsg: '',
    }
    const [state, setState] = useState(initialState);

    // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
        setIsLoading(true)
        console.log(">> register")

        // console.log(state.userInfo.password, state.userInfo.passwordRepeat)

        let re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

        if (!re.test(state.userInfo.username)) {
            setIsLoading(false)
            getError("Neplatný email.")
            setState({
                ...state,
                successMsg: '',
                errorMsg: 'Neplatný email.'
            })
            return
        }

        if (state.userInfo.password !== state.userInfo.passwordRepeat) {
            setFlaged(true)
        } else {
            setFlaged(false)

            const data = await registerUser(state.userInfo);
            if (data.success) {
                setState({
                    ...initialState,
                    successMsg: data.message,
                });
            }
            else {
                console.warn(data)
                alert("Nastala chyba")
                getError("Nastala chyba")
                setState({
                    ...state,
                    successMsg: '',
                    errorMsg: data.message
                });
            }
        }
        setIsLoading(false)
    }

    // On change the Input Value (username, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo: {
                ...state.userInfo,
                [e.target.name]: e.target.value
            }
        });


    }

    // Show Message on Success or Error
    let successMsg = '';
    let errorMsg = '';
    if (state.errorMsg) {
        errorMsg = <div className="notification notification--error">{state.errorMsg}</div>;
    }
    if (state.successMsg) {
        successMsg = <div className="notification notification--success">{state.successMsg}</div>;
    }

    let login_page_bg = ''
    if (props.login_bg) {
        login_page_bg = 'login_page'
    } else {
        login_page_bg = ''
    }

    if (isLoading) {
        return (
            <Loading />
        )
    }

    // check user permissions

    if (isAuth) {
        return (
            <div className={`_loginRegister ${login_page_bg}`}>
                <div className="register_form_container">
                    <Link to="/"> <div className="close_bnt"></div></Link>

                    <div className="container content_box cb-mid register_container">
                        <h1 className="main_h1">Registrace nového uživatele</h1>
                        <h2 className="sec_headline">login pro přihlášení je Váš email</h2>
                        <form onSubmit={submitForm} noValidate>
                            <div className="reg_inner_conainer">
                                <div className="form-control">
                                    <label className="text-gray inp-label">email</label>
                                    <div className="input input-fullWidth">
                                        <input placeholder="email" name="username" className="input" type="text" required value={state.userInfo.username} onChange={onChangeValue} />
                                    </div>
                                </div>
                                <div className="hlaf_inp_grid">
                                    <div className="form-control">
                                        <label className="text-gray inp-label">jméno</label>
                                        <div className="input input-fullWidth">
                                            <input placeholder="jméno" name="name" className="input" type="text" required value={state.userInfo.name} onChange={onChangeValue} />
                                        </div>
                                    </div>
                                    <div className="form-control">
                                        <label className="text-gray inp-label">příjmení</label>
                                        <div className="input input-fullWidth">
                                            <input placeholder="příjmení" name="surname" className="input" type="text" required value={state.userInfo.surname} onChange={onChangeValue} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-control">
                                    <label className="text-gray inp-label">heslo</label>
                                    <div className="input input-fullWidth">
                                        <input placeholder="••••••" name="password" className="input" type="password" required value={state.userInfo.password} onChange={onChangeValue} />
                                    </div>
                                </div>
                                <div className="form-control">
                                    <label className="text-gray inp-label">zopakovat heslo</label>
                                    <div className="input input-fullWidth">
                                        <input placeholder="••••••" name="passwordRepeat" className={isFlaged ? "has-error input" : "input"} type="password" required value={state.userInfo.passwordRepeat} onChange={onChangeValue} />
                                    </div>
                                </div>
                            </div>

                            <div className="container">
                                <div className="row reg_btns">
                                    <Link to="/" ><button className="button button--outlined button--small aligner aligner--centerVertical back_btn">zpět</button></Link>

                                    <div className="form-control">
                                        <button type="submit" className="button button--primay button--small aligner aligner--centerVertical">Registrovat</button>
                                    </div>
                                </div>
                            </div>

                        </form>

                    </div>
                </div>


                {errorMsg}
                {successMsg}
            </div>
        );
    }
    return <Error404 />;
}
export default Register
