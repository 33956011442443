export const START_YEAR = 2021
export const NUM_OF_YEARS = 10
export const MONTH_NAMES = ['Led', 'Úno', 'Bře', 'Dub', 'Kvě', 'Čer', 'Čvc', 'Srp', 'Zář', 'Říj', 'Lis', 'Pro']
export const MONTHS_PER_YEAR = 12
export const QUARTERS_PER_YEAR = 4
export const MONTHS_PER_QUARTER = 3
export const NUM_OF_MONTHS = NUM_OF_YEARS * MONTHS_PER_YEAR
export const MAX_TRACK_START_GAP = 4
export const MAX_ELEMENT_GAP = 8
export const MAX_MONTH_SPAN = 8
export const MIN_MONTH_SPAN = 2
export const NUM_OF_TRACKS = 10


// TODO skladani investic projit a kontrolovat zacatak konec a prostredek jesrli je tam místo

