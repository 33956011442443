/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react"
import { MainContext } from '../contexts/MainContext'
import Error404 from "../pages/Error404";
import './css/Clients.css'
import profile_pic from '../img/profile_pic.svg'
import { CircleProgress } from 'react-gradient-progress'
import UserDetail from "./UserDetail";

function Clients() {
    const { rootState, userData, getTableApi, pageApiUrl, eurConverstion } = useContext(MainContext)
    const { theUser } = rootState
    const [clientsData, setClientsData] = useState([])
    const [clientsAccounts, setClientsAccounts] = useState([])
    const [clientsStatus, setClientsStatus] = useState([])
    const [clientsDataOrg, setClientsDataOrg] = useState([])
    const [search, setSearch] = useState("")
    const [clientsStats, setClientsStats] = useState({
        count: 0,
        active: 0,
        combineInvested: 0
    })

    const [showUserDetail, setShowUserDetail] = useState(null)

    useEffect(() => {
        if (userData.loaded !== false) {
            getClientsData()
        }
    }, [userData])

    useEffect(() => {
        if (search.length !== 0) {
            searchUser()
        } else {
            getClientsData()
        }
    }, [search])


    const getClientsData = async () => {
        let requestClientData = await getTableApi('user_data')
        let requestAccountData = await getTableApi('user_accounts')
        let requestUserData = await getTableApi('users')

        if (Array.isArray(requestClientData)) {
            if (theUser.user_type === 'admin') {
                requestClientData = requestClientData.filter(item => item.id >= 10001)  // filter us 10001, 10003
            } else {
                requestClientData = requestClientData.filter(item => item.id !== userData.id)
                requestClientData = requestClientData.reverse()
            }

            let userAccounts = []
            let userStatus = []
            let activeUserCount = 0
            let combineInvested = 0

            requestClientData.forEach((user, i) => {
                let userAccount = requestAccountData.find(account => account.id === user.id)
                let rqUserData = requestUserData.find(item => item.id === user.id)
                userStatus.push(rqUserData.status)
                if (rqUserData.status === '1'){
                    activeUserCount++
                }
                if (userAccount){
                    userAccounts.push(userAccount)
                    combineInvested += parseInt(userAccount.currency === "eur" ? userAccount.locked_amount * eurConverstion : userAccount.locked_amount)
                }
            })
            
            setClientsData(requestClientData)
            setClientsDataOrg(requestClientData)
            setClientsAccounts(userAccounts)
            setClientsStatus(userStatus)
            setClientsStats({
                count: requestClientData.length,
                active: activeUserCount,
                combineInvested: combineInvested
            })
        }
    }

    const handleInput = (e) => {
        let value = e.target.value
        setSearch(value)
    }

    const lc = (string) => {
        if (string && string.length !== 0) {
            return string.toLowerCase()
        } else if (string === null) {
            return ""
        } else if (string.isInteger()) {
            return string.toString()
        }
        return string
    }

    const searchUser = () => {
        let result = clientsDataOrg.filter(user => {
            return ( user.id && user.id.toString().includes(search.toLowerCase()) )
            || lc(user.name).includes(search.toLowerCase())
            || lc(user.surname).includes(search.toLowerCase())
            || lc(user.email).includes(search.toLowerCase())
            || lc(user.phone).includes(search.toLowerCase())
            || lc(user.bank_account).includes(search.toLowerCase())
            || lc(user.birth_number).includes(search.toLowerCase())
        })
        // console.log(result)
        setClientsData(result)
    }

    const showClientDeatil = (user) => {
        setShowUserDetail(user)
    }

    const handleCloseClientDeatil = () => {
        setShowUserDetail(null)
    }

    if (showUserDetail !== null){
        return <UserDetail user={showUserDetail} close={handleCloseClientDeatil} />
    }

    if (userData && userData.loaded !== false && theUser.user_type !== 'admin' && theUser.user_type !== 'dealer'){
        return <Error404 />
    }

    return (<>
        <div className="fullpage_component_wapper">
            <div className="container content_box component_cb_fullpage clients_component">
                <div className="fullpage_component_headline">
                    <h3 className="cb_fullpage_label_right">Klienti</h3>
                    <p className="cb_fullpage_sublabel_right">Stav konta a investice vašich klientů</p>
                </div>

                <div className="cl_header_grid">
                    <div className="grid_item">
                        <p className="legend">Celkem klientů</p>
                        <p className="value">{clientsStats.count}</p>
                    </div>
                    <div className="grid_item">
                        <p className="legend">Celkové investice</p>
                        <p className="value amount">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: "CZK", maximumFractionDigits: 0 }).format(clientsStats.combineInvested)}</p>
                    </div>
                    <div className="grid_item">
                        <p className="legend">Aktivní</p>
                        <p className="value">{clientsStats.active + " / " + clientsStats.count}</p>
                    </div>
                    <a className="button button--small reg_button" href="/register">Registrace</a>
                </div>

                <div className="search_input">
                    <div className="input input-full">
                        <input type="text" value={search} placeholder="hledat" onChange={e => handleInput(e)} />
                    </div>
                </div>

                <div className="main_content_box">
                    <div className="cl_line line_legend">
                        <p>klient</p>
                        <p>investováno</p>
                        <p>volné prostředky</p>
                        <p>variabilní symbol</p>
                        <p>status</p>
                    </div>

                    <div className="cl_line_box_wrapper">
                        {Array.isArray(clientsData) && clientsData.length !== 0 && Array.isArray(clientsAccounts) && clientsAccounts.length !== 0 ? clientsData.map((data, i) => {

                            let avalibleAmount = parseInt(clientsAccounts[i].amount) - parseInt(clientsAccounts[i].locked_amount)
                            let lockedAmount = parseInt(clientsAccounts[i].locked_amount)
                            let graphValueInvested = 0
                            if (parseInt(clientsAccounts[i].amount) !== 0){
                                graphValueInvested = Math.round(lockedAmount / parseInt(clientsAccounts[i].amount) * 100)
                            }
                        
                            return (
                                <div className='cl_line_box' key={i} onClick={() => showClientDeatil(data)}>
                                    { data.profile_picture !== '' && data.profile_picture ?
                                        <img src={pageApiUrl + 'uploads/' + data.profile_picture} className="profile_img" alt="user_profile" /> :
                                        <img src={profile_pic} className="profile_img" alt="user_profile" />
                                    }
                                    <p>{data.name + " " + data.surname}</p>
                                    <p className="currency">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: clientsAccounts[i].currency, maximumFractionDigits: 0 }).format(lockedAmount)}</p>
                                    <p className="currency">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: clientsAccounts[i].currency, maximumFractionDigits: 0 }).format(avalibleAmount)}</p>
                                    <p>{data.id}</p>
                                    <div className="circular_progressbar_small inv_overview_circ">
                                        <CircleProgress percentage={graphValueInvested} primaryColor={['#71E7FF', '#A95BFD']} strokeWidth={2.5} fontFamily='Roboto Mono' width={42} fontSize='9px' fontColor='#2F3E68' secondaryColor='#EEEEEE' />
                                    </div>
                                    <p className={clientsStatus[i] == 1 ? 'green_cl' : 'red_cl'} >{clientsStatus[i] == 1 ? 'aktivní' : 'neaktivní'}</p>
                                </div>
                            )


                        }) : <p className="empty_label">Nebyly nalezeny žádní klienti.</p>}

                        <br />
                        <br />
                    </div>
                </div>


            </div>

            <br />
            <br />
        </div>

    </>)
}

export default Clients