/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import './css/InvestmentOverview.css'
import { MainContext } from '../contexts/MainContext'
import { CircleProgress } from 'react-gradient-progress'

// converts SQL timestamp to plain text date (without time)
function timestampToDate(timestampDate) {
    let dateStr = timestampDate;
    let a = dateStr.split("_");
    return a[0];
}

function InvestmentOverview(){
    const { userData, updateUI, convertTimestamp, getTableApi } = useContext(MainContext)

    // wait for userData to be loaded then run function
    useEffect(() => {       
        if (userData.id) {
            getInvestmentData()
            getClientsData()
        }
    }, [userData, updateUI])

    const [investmentData, setInvestmentData] = useState([]) 

    const getInvestmentData = async () => {
        let investmentData = await getTableApi('investments')

        // sort by start_date
        if (Array.isArray(investmentData) && investmentData.length > 1){
            investmentData.sort((a, b) => convertTimestamp(a.start_date) - convertTimestamp(b.start_date))
        }
        investmentData.reverse()
        
        setInvestmentData(investmentData)
    }

    const [clientsData, setClientsData] = useState([]) 

    const getClientsData = async () => {
        let requestClientsData = await getTableApi('user_data')
        if (Array.isArray(requestClientsData)){
            setClientsData(requestClientsData)
        }
        
    }

    return(
        <div className="container content_box component_cb investment_overview_wrapper">
            <p className="container_box_label">Nové investice</p>
            
            <div className="inv_overview_container">
                
                <div className="investment_table_wrapper">
                    <div className="itw_line line_legend">
                        <p>klient</p>
                        <p>datum</p>
                        <p>úrok</p>
                        <p>délka</p>
                        <p>částka</p>
                        <p>progres</p>
                    </div>

                    
                    {Array.isArray(investmentData) && clientsData.length !== 0 && investmentData.length !== 0 ?
                        <div className="inv_line_box_wrapper">
                            <p className="pmt_context_line">nové investice za posledních 30 dní</p>

                            {Array.isArray(investmentData) && investmentData.map((data, i)=> {
                                let modifiedDateStart = timestampToDate(data.start_date)
                                let clientData = clientsData.find(e => e.id === data.user_id)
                                let clientName = clientData.name + " " + clientData.surname
                                
                                let progressLength = convertTimestamp(data.end_date) - convertTimestamp(data.start_date)
                                let startToNow = Date.now() - convertTimestamp(data.start_date)

                                let progressPerc = Math.round((startToNow / progressLength) * 100) < 100 ? Math.round((startToNow / progressLength) * 100) : 99

                                var dateBefore = new Date()
                                dateBefore.setDate(dateBefore.getDate() - 30)

                                if (dateBefore < convertTimestamp(data.start_date)){
                                    return (
                                        <div className='inv_line_box' key={i}>
                                            <p>{clientName}</p>
                                            <p>{modifiedDateStart}</p>
                                            <p>{data.interest}%</p>
                                            <p>{parseInt(data.investment_type) === 1 ? "4 roky" : "2 roky"}</p>
                                            <p className="currency">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(data.amount)}</p>
                                            <div className="circular_progressbar_small inv_overview_circ">
                                                <CircleProgress percentage={progressPerc} primaryColor={['#71E7FF', '#A95BFD']} strokeWidth={2.5} fontFamily='Roboto Mono' width={42} fontSize='9px' fontColor='#2F3E68' secondaryColor='#EEEEEE' />
                                            </div>
                                        </div>
                                    )
                                }
                                return (null)
                            })}

                            <p className="pmt_context_line">všechny investice</p>

                            {Array.isArray(investmentData) && clientsData.length !== 0 && investmentData.map((data, i) => {
                                let modifiedDateStart = timestampToDate(data.start_date)
                                let clientData = clientsData.find(e => e.id === data.user_id)
                                let clientName = clientData.name + " " + clientData.surname

                                let progressLength = convertTimestamp(data.end_date) - convertTimestamp(data.start_date)
                                let startToNow = Date.now() - convertTimestamp(data.start_date)

                                let progressPerc = Math.round((startToNow / progressLength) * 100) < 100 ? Math.round((startToNow / progressLength) * 100) : 99

                                return (
                                    <div className='inv_line_box' key={i}>
                                        <p>{clientName}</p>
                                        <p>{modifiedDateStart}</p>
                                        <p>{data.interest}%</p>
                                        <p>{parseInt(data.investment_type) === 1 ? "4 roky" : "2 roky"}</p>
                                        <p className="currency">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(data.amount)}</p>
                                        <div className="circular_progressbar_small inv_overview_circ">
                                            <CircleProgress percentage={progressPerc} primaryColor={['#71E7FF', '#A95BFD']} strokeWidth={2.5} fontFamily='Roboto Mono' width={42} fontSize='9px' fontColor='#2F3E68' secondaryColor='#EEEEEE' />
                                        </div>
                                    </div>
                                )
                                
                            })}
                        </div> : 
                            <p className="pmt_context_line_no_inv">žádné investice</p>}

                </div>
            </div>
        </div>

        
            
    )
}

export default InvestmentOverview
