/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react"
import { MainContext } from '../contexts/MainContext'
import './css/Network.css'

import 'react-tree-graph/dist/style.css'
import { AnimatedTree } from 'react-tree-graph';


function Network() {
    const { userData, getTableApi, getError } = useContext(MainContext)
    const [networkData, setNetworkData] = useState({})

    const { innerWidth: windowsWidth, innerHeight: height } = window;

    const [graphHeight, setGraphHeight] = useState(2000)

    useEffect(() => {
        if (userData.loaded !== false) {
            getNetworkData()
        }
    }, [userData])

    const getNetworkData = async () => {
        let getUsers = await getTableApi("user_data")

        if (!Array.isArray(getUsers)) {
            getError("Chyba načítání dat.")
            return
        }

        const rootUserId = 10001
        let rootUser = getUsers.find(user => parseInt(user.id) === rootUserId)

        let tree = await createTree(rootUser, getUsers)

        console.log(tree)
        tree.textProps = { x: -25, y: 35 }

        let calcGraphHeight = getUsers.length * 30
        calcGraphHeight = calcGraphHeight < 80 ? 80 : calcGraphHeight
        setGraphHeight(calcGraphHeight)
        console.log(">height ", calcGraphHeight)

        setNetworkData(tree)
    }

    const createTree = (root, data) => {
        let childrenData = data.filter(user => user.parent === root.id)
        let children = []

        for (let i = 0; i < childrenData.length; i++) {
            if (childrenData[i].id !== root.id) {
                children.push(createTree(childrenData[i], data))
            }
        }

        let node = {
            name: root.name + " " + root.surname,
            textProps: { x: -45, y: 20 },
            children: children
        }
        return node
    }


    return (<>
        <div className="fullpage_component_wapper_wide">
            <div className="component_cb_fullpage_large network_component">
                <div className="fullpage_component_headline">
                    <h3 className="cb_fullpage_label_right">Network</h3>
                    <p className="cb_fullpage_sublabel_right">Síť Vašich klientů</p>
                </div>

                <div className={"main_content_box" + (graphHeight < 400 && " top_padding")}>

                    <AnimatedTree
                        data={networkData}
                        height={graphHeight}
                        width={windowsWidth * 0.85}
                        nodeRadius={15}
                        margins={{ top: 30, bottom: 0, left: 10, right: 160 }} />

                </div>

            </div>

            <br />
            <br />
        </div>

    </>)
}

export default Network