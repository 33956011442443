/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react"
import { MainContext } from '../contexts/MainContext'

import './css/TextEdit.css'
import Loading from "./Loading";


function TextEdit() {
    const { userData, getTableApi, getError, postTableApi } = useContext(MainContext);
    const [textData, setTextData] = useState([])
    const [textDataChanges, setTextDataChanges] = useState([])
    const [unsaved, setUnsaved] = useState(false)

    useEffect(() => {
        if (userData.loaded !== false) {
            getTransactionData()
        }
    }, [userData])

    const getTransactionData = async () => {
        let requestUserDocuments = await getTableApi('text_data')

        if (!Array.isArray(requestUserDocuments)) {
            getError("Nastala chyba: Načítání textů")
        }

        setTextData(requestUserDocuments)

    }

    const [loading, setLoading] = useState(true)

    useEffect(() => {       // wait for userData to be loaded than run function
        setTimeout(() => {
            setLoading(false)
        }, 400)
    }, [textData])


    const updateContent = (e, key) => {
        let data = textData[key];
        data = e.target.value;
        setUnsaved(true)
        let getTextData = [...textData]
        getTextData[key] = {
            ...textData[key],
            content: data
        }
        setTextData(getTextData)
        let newTextDataChanges = textDataChanges
        if (!newTextDataChanges.includes(key)){
            newTextDataChanges.push(key)
        }
        setTextDataChanges(newTextDataChanges)
    }

    const updateTable = async () => {
        setLoading(true)

        let textPostData = {
            data: textData,
            changes: textDataChanges,
            post_table: 'text_data'
        }
        let respUpdate = await postTableApi(textPostData)

        if (!respUpdate.hasOwnProperty('success') || respUpdate.success !== 1) {
            getError("Nastala chyba: " + respUpdate)
            return
        }

        setUnsaved(false)
        setLoading(false)
    }


    if (!userData.name || loading) {
        return (<>
            <Loading type="page"/>
        </>)
    }

    return (<>
        <div className="fullpage_component_wapper">
            <div className="container content_box component_cb_fullpage text_edit_component">
                <div className="fullpage_component_headline">
                    <h3 className="cb_fullpage_label_right">Editace textů</h3>
                    {/* <p className="cb_fullpage_sublabel_right">Výpis všech smluv klientů</p> */}
                </div>


                <div className="main_content_box">
                    
                    <div className="text_edit_box_wrapper">
                        {Array.isArray(textData) && textData.length !== 0 ? textData.map((data, i) => {
                            return(
                                <div className="text_edit_item full_input" key={i}>
                                    <label className="input_label">{data.item_name}</label>
                                    <div className="input input">
                                        <input type="text" value={data.content} placeholder="" onChange={e => updateContent(e, i)} />
                                    </div>
                                </div>
                            )   
                        }) : <p className="empty_label">Nebyly nalezeny žádné texty.</p>}

                        <br />
                    </div>
            
                    <div className={`container content_box cb-small-short action_box_container box_unsaved ${unsaved && 'highlight_box'}`}>
                        <div className="action_box_wrpaper">
                            <p className="label">{unsaved ? 'Uložit změny provedené na webu: ' : 'Nebyly provedeny žádné změny '} </p>
                            <button onClick={updateTable} className="button button--small update_btn">Uložit</button>
                        </div>
                    </div>
                    
                </div>

            </div>

            <br />
            <br />
        </div>

    </>)
}

export default TextEdit