/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { MainContext } from '../contexts/MainContext'
import './css/UserDocuments.css'

function UserDocuments(props){
    const { userData, getTableWhereApi, pageApiUrl } = useContext(MainContext)

    const [userDocuments, setUserDocuments] = useState([])

    useEffect(() => {       // wait for userData to be loaded than run function
        if(userData.loaded !== false){
            getUserDocuments()
        }
    }, [userData])

    const getUserDocuments = async () => {
        let requestUserDocuments = await getTableWhereApi('user_documents', 'user_id', props.userId, 'int')
        if( (requestUserDocuments && requestUserDocuments.hasOwnProperty('success') && requestUserDocuments.success === 0) 
            || requestUserDocuments.length === 0){
            return
        }
        setUserDocuments(requestUserDocuments)
    }

    if(userDocuments.length === 0){
        return (
            <div className="user_documents_wrapper">
                <p className="empty_label">žádné dokumenty</p>
            </div>
        )
    }

    return (
        <div className="user_documents_wrapper">
            {userDocuments.map((data, i) => {
                return (
                    <a href={pageApiUrl + 'uploads/' + data.file_name} target="_blank" rel="noreferrer" key={i}>
                        <div className='user_documents_line' key={i}>
                            <p>Smlouva investice č.{ data.investment_id + ' ( ' + data.timestemp.split('_')[0] + ' ) ' }</p>
                            <div className="download_btn"></div>
                        </div>  
                    </a>
                )
            })}
        </div>
    )
}

export default UserDocuments