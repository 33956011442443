import { CircleProgress } from 'react-gradient-progress'

function CircleProgressBar(props) {

    let color = ['#27DEFF', '#A95BFD']
    let fontColor = '#2F3E68'

    if (props.fontColor) {
        fontColor = props.fontColor
    }

    if (props.gradient === 1) {
        color = ['#71E7FF', '#00BAFF']
    }

    if (props.gradient === 2) {
        color = ['#6404CB', '#A95BFD']
    }

    if (props.type === "small") {
        return (
            <CircleProgress percentage={props.data} primaryColor={color} strokeWidth={3} width={60} fontColor={fontColor} secondaryColor='#EEEEEE' />
        )
    }

    if (props.type === "inline") {
        return (
            <CircleProgress percentage={props.data} primaryColor={color} strokeWidth={2.5} fontFamily='Roboto Mono' width={42} fontSize='9px' fontColor={fontColor} secondaryColor='#EEEEEE' />
        )
    }

    return (
        <CircleProgress percentage={props.data} primaryColor={color} strokeWidth={4} width={80} fontColor={fontColor} secondaryColor='#EEEEEE' />
    )
}

export default CircleProgressBar