/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import "./css/ChangeUserPerm.css"
import { MainContext } from '../contexts/MainContext'
import profile_pic from '../img/profile_pic.svg'

function ChangeUserPerm(props) {
    const { getError, pageApiUrl, getTableWhereApi, postTableApi, getSuccess } = useContext(MainContext)
    
    useEffect(() => {
        getUserData()
    }, [])

    const [classes, setClasses] = useState(["", ""])

    const handleClose = () => {
        props.close()
    }

    const getUserData = async () => {
        let reqUsersData = await getTableWhereApi('users', 'id', props.user.id, 'int')
        if (!Array.isArray(reqUsersData) || reqUsersData.length === 0) {
            getError('Nastala chyba načítání dat ' + reqUsersData)
            return
        }
        let userData = reqUsersData[0]

        if (userData.user_type === "user") {
            setClasses(["active0", ""])
        }
        if (userData.user_type === "dealer") {
            setClasses(["active", ""])
        }
        if (userData.user_type === "admin") {
            setClasses(["active", "active"])
        }
    }

    const setLevel = async (level) => {
        if(level === 0){
            setClasses(["active0", ""])
        }
        if(level === 1){
            setClasses(["active", ""])
        }
        if(level === 2){
            setClasses(["active", "active"])
        }

        let reqUsersData = await getTableWhereApi('users', 'id', props.user.id, 'int')
        if (!Array.isArray(reqUsersData) || reqUsersData.length === 0) {
            getError('Nastala chyba načítání dat ' + reqUsersData)
            return
        }
        let userData = reqUsersData[0]

        if(level === 0){
            userData.user_type = "user"
        }
        if(level === 1){
            userData.user_type = "dealer"
        }
        if(level === 2){
            userData.user_type = "admin"
        }

        let tableObj = {
            data: [userData],
            changes: [0],
            post_table: 'users'
        }
        let response = await postTableApi(tableObj)

        if (response && response.hasOwnProperty('success') && response.success === 0) {
            getError("Nastala chyba při ukládání.")
            return
        }

        getSuccess("uloženo")
    }

    return(<>
        <div id="change_user_perm">
            <div className="close_bnt" onClick={handleClose}></div>

            <div className="centered-box">
                
                <div className="container content_box component_cb cb-small change_user_perm_box">
                    <p className="cb_label_right_large">Oprávnění uživatele</p>

                    <div className='user_info_page'>
                        {props.user.profile_picture !== '' && props.user.profile_picture ?
                            <img src={pageApiUrl + 'uploads/' + props.user.profile_picture} className="profile_img" alt="user_profile" /> :
                            <img src={profile_pic} className="profile_img" alt="user_profile" />
                        }
                        <p>{props.user.name}</p>
                    </div>
                    
                    <div class="progress-bar">
                        <div className={"step " + classes[0]} onClick={() => {setLevel(0)}}></div>
                        <div className={"step " + classes[1]} onClick={() => { setLevel(1) }}></div>
                        <div className="step" onClick={() => { setLevel(2) }}></div>
                    </div>

                </div>
            </div>
        </div>
    </>)
}

export default ChangeUserPerm


