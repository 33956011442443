/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { MainContext } from '../contexts/MainContext'
import './css/CalculateInterest.css'
import { CircleProgress } from 'react-gradient-progress'
import LoadingPayout from './LoadingPayout'
import ConfirmDialog from './ConfirmDialog'
import ManualAccountManager from './ManualAccountManager'

function CalculateInterest() {
    const { userData, numFormat, getTableWhereApi, convertTimestamp, getTimestemp, getError,
        insertTableApi, postTableApi, getTableApi, setUpdateUi, calculateInterest, daysBetweenTwoDates, sendSMS } = useContext(MainContext)
    const [showComfirmDialog, setShowComfirmDialog] = useState(false)
    const [showManualDialog, setShowManualDialog] = useState(false)
    const [investmentsToBeCompeted, setInvestmentsToBeCompeted] = useState([])
    const [nextPayDay, setNextPayDay] = useState("")
    const [daysUntilNextPayout, setDaysUntilNextPayout] = useState({
        days: 0,
        percent: 0,
        date: ""
    })

    const [payoutData, setPayoutData] = useState({
        payoutTotal: 0,
        averagePayout: 0,
        countInvestments: 0
    })

    const getAccountData = async () => {
        let requestAccountData = await getTableApi('user_accounts')
        let accountsAmount = 0
        let accountsLockedAmount = 0
        let accountsCount = 0

        if (requestAccountData && requestAccountData.hasOwnProperty('success') && requestAccountData.success === 0) {
            getError("Nastala chyba: getAccountData() " + requestAccountData)
            return
        }

        requestAccountData.forEach((e) => {
            if (e.id >= 10010) {  // filter admins
                accountsAmount += !e.amount ? 0 : parseInt(e.amount)
                accountsLockedAmount += !e.locked_amount ? 0 : parseInt(e.locked_amount)
                accountsCount++
            }
        })

        return {
            amount: accountsAmount,
            amountLocked: accountsLockedAmount,
            accountCount: accountsCount,
            loaded: true
        }
    }

    const [payoutDataArray, setPayoutDataArray] = useState([])
    const [showLoading, setShowLoading] = useState(false)
    const [loadingOutData, setLoadingOutData] = useState("")
    const [loadingOutDataProgress, setLoadingOutDataProgress] = useState(0)

    useEffect(() => {
        if (userData.loaded !== false){
            getNeerestPayDate()
        }   
    }, [userData])

    useEffect(() => {
        if (userData.loaded !== false && nextPayDay !== ""){
            checkPayouts()
        }   
    }, [userData, nextPayDay])

    const getNeerestPayDate = () => {
        const date = new Date()
        let dayToday = date.getDate()
        
        let paMont = date.getMonth() + 1
        let paYear = date.getFullYear()
        let paDay = 5
               
        if (dayToday > 5){
            if (paMont === 12){
                paMont = 1
                paYear += 1
            } else {
                paMont += 1
            }
        }

        let payoutDate = numFormat(paDay, 2) + "." + numFormat(paMont, 2) + "." + paYear + "_08:00:00"
        setNextPayDay(payoutDate)
    }

    const checkPayouts = async () => {
        let getLastPayoutDate = await getTableWhereApi('system', 'val_key', 'last_payout_date', 'string')

        if (!Array.isArray(getLastPayoutDate) || getLastPayoutDate.length === 0 || !getLastPayoutDate[0].value){
            getError("Nastala chyba: checkPayouts()")
            return
        }

        let lastPayoutDate = getLastPayoutDate[0].value

        if (convertTimestamp(nextPayDay) > convertTimestamp(lastPayoutDate)){
            const dateToday = getTimestemp()

            calcDaysToNextPayout(lastPayoutDate)
            if (dateToday.split("_")[0] !== lastPayoutDate.split("_")[0]){
                calculateNextPayout()

            }
        }
    }

    const calcDaysToNextPayout = (lastPayoutDate) => {
        const date = new Date()
        let daysUntilNextPayout = daysBetweenTwoDates(convertTimestamp(nextPayDay), date)
        let fromLastPayToNext = daysBetweenTwoDates(convertTimestamp(nextPayDay), convertTimestamp(lastPayoutDate))
        let calcProcentage = Math.floor((fromLastPayToNext - daysUntilNextPayout) * 100 / fromLastPayToNext)

        setDaysUntilNextPayout({
            days: daysUntilNextPayout,
            percent: calcProcentage,
            date: nextPayDay.split("_")[0]
        })
    }

    const calculateNextPayout = async () => {
        // get all user investments
        let requestActiveInvestments = await getTableApi('investments')
        if (!Array.isArray(requestActiveInvestments)){
            console.warn("> Error calculateNextPayout()", requestActiveInvestments)
            getError("Nastala chyba při načítání investic. [calculateNextPayout()]")
            return
        }
        const dateToday = new Date()
        
        let investmentsToBeCompetedAr = []
        requestActiveInvestments.forEach((inv) => {
            if (convertTimestamp(inv.end_date) <= dateToday && parseInt(inv.completed) === 0){
                investmentsToBeCompetedAr.push(inv)
            }
        })
        setInvestmentsToBeCompeted(investmentsToBeCompetedAr)

        requestActiveInvestments = requestActiveInvestments.filter(item => dateToday <= convertTimestamp(item.end_date) 
            && dateToday >= convertTimestamp(item.start_date) 
            && parseInt(item.completed) === 0 )        // TODO: recheck (<) investment last day

        let investmentsInterest = []
        let totalPaidOut = 0

        let requestUserData = await getTableApi('user_data')
        if (!Array.isArray(requestUserData) || requestUserData.length === 0) {
            getError("Nastala chyba: " + requestUserData)
            return
        }

        // for each calc interest
            // calculate intereset per investment and add them up to user  
        requestActiveInvestments.forEach((item, i) => {
            let getUserData = requestUserData.find(user => user.id == item.user_id)

            if (item.user_id >= 10010 && getUserData.funds_in_rs == 1){  // eliminate testing accounts
                    
                let interest = Math.floor(calculateInterest(item.amount, item.length, item.interest, 2) / (12 * item.length))

                totalPaidOut += interest

                investmentsInterest.push({
                    userId: item.user_id,
                    investmentId: item.id,
                    interestAmount: interest,
                    invAmount: item.amount

                })
            }
        })
        let averagePayout = Math.floor(totalPaidOut / requestActiveInvestments.length)
        setPayoutDataArray(investmentsInterest)
        setPayoutData({
            payoutTotal: totalPaidOut,
            averagePayout: averagePayout,
            countInvestments: investmentsInterest.length,
        })
    }


    // user interactions
    const handlePayout = async () => {
        console.log(">> auto payout ------------------------")
        setShowComfirmDialog(false)

        // NOTE: Add a confirm dialog Yes | Cancel OK

        // create transaction records OK
        // add interest to user accounts OK
        // create analytics record ... OK
        // set last payout date (system) OK

        // Error handleing OK
        // check if found in rs is active OK
        console.log(">> payoutDataArray", payoutDataArray)
        if (payoutDataArray.length === 0){
            console.warn("no payout to add")
            getError("Úroky již byly vypsány, zkuste to zítra.")
            return
        }

        setShowLoading(true)


        let userAccounts = await getTableApi('user_accounts')
        if (!Array.isArray(userAccounts) || userAccounts.length === 0) {
            getError("Nastala chyba: " + userAccounts)
            setShowLoading(false)
            return
        }

        let requestUserData = await getTableApi('user_data')
        if (!Array.isArray(requestUserData) || requestUserData.length === 0) {
            getError("Nastala chyba: " + requestUserData)
            setShowLoading(false)
            return
        }

        let accountsPostData = {
            data: [],
            changes: [],
            post_table: 'user_accounts'
        }

        let userInterestData = payoutDataArray.reduce(function (r, a) {
            r[a.userId] = r[a.userId] || [];
            r[a.userId].push(a);
            return r;
        }, Object.create(null));

        console.log(">> requestUserData", requestUserData)

        // get summ of interests, user id and count
        userInterestData = Object.keys(userInterestData).map((key) => {
            console.log(">> user data", requestUserData.find(user => user.id == key), key)
            let tel = requestUserData.find(user => user.id == key).phone;
            return {
                userId: key,
                tel: tel.length === 9 || tel.length === 13 ? (tel.length === 9 ? "+420" + tel : tel) : null,
                count: userInterestData[key].length,
                sum: userInterestData[key].map(item => item.interestAmount).reduce((prev, curr) => prev + curr, 0)
            }
        })

        for (let i = 0; i < payoutDataArray.length; i++){
            let item = payoutDataArray[i]


            let userAccount = userAccounts.find(account => account.id == item.userId)
            let getUserData = requestUserData.find(user => user.id == item.userId)

            if (item.userId < 10010 || getUserData.funds_in_rs === '0') {    // eliminate testing accounts
                console.log(">skip", getUserData.funds_in_rs, item.userId)
                continue
            }

            setLoadingOutData(i + ". inv: " + item.investmentId + " | " + item.invAmount + "Kč úrok: +" + item.interestAmount + "Kč")
            console.log(">> payout: " + i + ". inv: " + item.investmentId + " | " + item.invAmount + "Kč úrok: +" + item.interestAmount + "Kč")

            if (!userAccount) {
                getError("Nastala chyba: useraccount not found " + item.userId)
                setShowLoading(false)
                return
            }

            let moveProcentage = Math.floor(((item.interestAmount / userAccount.amount) * 100) * 100) / 100 // round to 2 deciamls
            let transactionObj = {
                data: {
                    id: null,
                    user_id: item.userId,
                    amount: item.interestAmount,
                    acount_move: moveProcentage,
                    type: 1,
                    description: 'Výnos z investice',
                    // description: 'Výnos z investice id:' + item.investmentId,
                    timestemp: getTimestemp()
                },
                post_table: 'transactions'
            }
            let insertResp = await insertTableApi(transactionObj)
            
            if (!insertResp.hasOwnProperty('success') || insertResp.success !== 1 ){
                getError("Nastala chyba: " + insertResp)
                setShowLoading(false)
                return
            }
            
            let editedUserAccount = {
                id: userAccount.id,
                amount: parseInt(userAccount.amount) + item.interestAmount,
                locked_amount: userAccount.locked_amount
            }
            accountsPostData.data.push(editedUserAccount)
            accountsPostData.changes.push(i)

           
            let calcProgress = Math.floor(i * 95 / payoutDataArray.length)  // up to 95% last 5% is account update a analytics ...
            setLoadingOutDataProgress(calcProgress)
        }

        let postResp = await postTableApi(accountsPostData)
        if (!postResp.hasOwnProperty('success') || postResp.success !== 1) {
            getError("Nastala chyba: " + postResp)
            setShowLoading(false)
            return
        }

        let getUserAccountData = await getAccountData()

        let analyticsObj = {
            data: {
                id: null,
                complete_amount: getUserAccountData.amount,
                locked_amount: getUserAccountData.amountLocked,
                timestemp: getTimestemp()
            },
            post_table: 'analytics'
        }
        if (!isNaN(getUserAccountData.amount) && !isNaN(getUserAccountData.amountLocked)) {
            let insertAnalyticsResp = await insertTableApi(analyticsObj)
            if (!insertAnalyticsResp.hasOwnProperty('success') || insertAnalyticsResp.success !== 1) {
                getError("Nastala chyba: " + insertAnalyticsResp)
                setShowLoading(false)
                return
            }
        } else {
            console.warn(">> not all data for analytics: amount, amountLocked ", getUserAccountData.amount, getUserAccountData.amountLocked)
        }

        // update last payout date

        let lastPayoutDateRq = await getTableWhereApi('system', 'val_key', 'last_payout_date', 'string')
        if (!Array.isArray(lastPayoutDateRq) || lastPayoutDateRq.length === 0) {
            getError("Nastala chyba: " + lastPayoutDateRq)
            setShowLoading(false)
            return
        }

        let newlastPayoutDate = lastPayoutDateRq[0]
        newlastPayoutDate.value = getTimestemp()
        let systemPostData = {
            data: [newlastPayoutDate],
            changes: [0],
            post_table: 'system'
        }

        let postSystemResp = await postTableApi(systemPostData)
        if (!postSystemResp.hasOwnProperty('success') || postSystemResp.success !== 1) {
            getError("Nastala chyba: " + postSystemResp)
            setShowLoading(false)
            return
        }

        let smsMessagesData = userInterestData.map((item, index) => {
            return {
                message: `Na Váš účet FutureInvest byl připsán výnos z investic (${item.count}) celkem v hodnotě ${new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(item.sum)}`,
                phone: item.tel
                // phone: index % 2 === 0 ? "+420739146107" : "+420737056612",
            }
        })

        let ret = await sendSMS(smsMessagesData)
        // console.log(ret)

        console.log(investmentsToBeCompeted)

        // Complete investments that ended and free up funds
        for (let i = 0; i < investmentsToBeCompeted.length; i++){
            let inv = investmentsToBeCompeted[i]

            setLoadingOutData(i + ". inv: " + inv.id + " | " + inv.amount + "Kč - ukončení investice.")
            console.log(">> inv_end: " + i + ". inv: " + inv.id + " | " + inv.amount + "Kč - ukončení investice.")

            let userAccount = userAccounts.find(account => account.id == inv.user_id)

            let newAccountData = userAccount
            newAccountData.locked_amount = userAccount.locked_amount - inv.amount
            inv.completed = 1

            let accountsPostData = {
                data: [newAccountData],
                changes: [0],
                post_table: 'user_accounts'
            }

            let postRespAccount = await postTableApi(accountsPostData)
            if (!postRespAccount.hasOwnProperty('success') || postRespAccount.success !== 1) {
                getError("Nastala chyba: " + postRespAccount)
                setShowLoading(false)
                return
            }

            let invPostData = {
                data: [inv],
                changes: [0],
                post_table: 'investments'
            }

            let postRespInv = await postTableApi(invPostData)
            if (!postRespInv.hasOwnProperty('success') || postRespInv.success !== 1) {
                getError("Nastala chyba: " + postRespInv)
                setShowLoading(false)
                return
            }
        }


        setUpdateUi(true)
        setShowLoading(false)
        await getNeerestPayDate()
        await checkPayouts()

        setPayoutData({
            payoutTotal: 0,
            averagePayout: 0,
            countInvestments: 0
        })

    }

    const handleCloseConfirm = async () => {

        // let messageData = [
        //     {
        //         message: "Byl vám připsán výnos z investice č. 302020 v hodnotě 6540 Kč",
        //         phone: "+420739146107"
        //     }
        // ]
        // let ret = await sendSMS(messageData)

        // console.log(ret)

        setShowComfirmDialog(false)
    }

    const handleCloseManualDialog = () => {
        setShowManualDialog(false)
    }

    // manual payouts


    const handlePayoutManual = () => {
        setShowManualDialog(true)
    }

    if(showLoading){
        return (<>
            <LoadingPayout data={loadingOutData} progress={loadingOutDataProgress} />
        </>)
    }


    if (showComfirmDialog){
        return (<>
            <ConfirmDialog confirm={handlePayout} close={handleCloseConfirm} text="Automaticky připsat všechny úroky?" />
        </>)
    }


    if (showManualDialog){
        return (<>
            <ManualAccountManager closeCallBack={handleCloseManualDialog} />
        </>)
    }
    

    // check if interest was added this mont (use service table) OK
        // find neerest 5th day in mont OK
        // if interest was not added yet show prompt add countdown OK

    // get all users  OK
        // get all user investments OK
        // calculate intereset per investment and add them up to user OK

    // create transaction records OK
    // add interest to user accounts OK
    // create analytics record OK


    return (<>
        <div className="container content_box component_cb interest_calc">
            <p className="cb_label_right">Vyplácení úroků</p>

            <div className="interest_calc_grid">

                <div className="grid_content_box type1">
                    <h3 className="stat_h3">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(payoutData.payoutTotal) }</h3>
                    <p className="legend">celkem k vyplacení</p>
                </div>

                <div className="grid_content_box type1">
                    <h3 className="stat_h3">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(payoutData.averagePayout)}</h3>
                    <p className="legend">průměrný úrok / inv.</p>
                </div>

                <div className="circular_progressbar cr_prg_interest_calc">
                    <CircleProgress percentage={daysUntilNextPayout.percent} primaryColor={['#71E7FF', '#00BAFF']} strokeWidth={4} width={80} fontColor='#2F3E68' secondaryColor='#EEEEEE' />
                    <p className="legend">Zbývá {daysUntilNextPayout.days} dní <br /> <span className="light">{daysUntilNextPayout.date}</span> </p>
                </div>

                <div className="grid_content_box type1">
                    <h3 className="stat_h3">{payoutData.countInvestments}</h3>
                    <p className="legend">počet investic</p>
                </div>
                <p>(výpis se vždy provede k dnešnímu dni)</p>
            </div>

            <div className="buttons_grid">
                <button className="button button--small button--outlined payout_btn" onClick={handlePayoutManual} >
                    zadat ručně
                </button>
                <button className="button button--small payout_btn" onClick={() => { setShowComfirmDialog(true) }} >
                    připsat všechny
                </button>
            </div>


        </div>
    </>)
}


export default CalculateInterest