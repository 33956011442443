import './css/app.css'
import './css/sierra.css'
import Navbar from './components/Navbar'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Register from './components/Register'
import MainContextProvider from './contexts/MainContext'

import Home from './pages/Home'
import TransactionsPage from './pages/TransactionsPage'
import ClientsPage from './pages/ClientsPage'
import SettingsPage from './pages/SettingsPage'
import DocsPage from './pages/DocsPage'
import TextEditPage from './pages/TextEditPage'
import ChangePass from './components/ChangePass'
import Error404 from './pages/Error404'
import LogsPage from './pages/LogsPage';
import TimelinePage from './pages/TimelinePage'

import InvestmentsPage from './pages/InvestmentsPage'
import NetworkPage from './pages/NetworkPage'


function App() {
    console.log("React App build by earc 2021")
    
    return (
        <>
            <MainContextProvider>
                <BrowserRouter>
                    <Navbar/>
                    <Routes>
                        <Route path="/" element={<Home />}>
                            <Route index element={<Home />} />
                        </Route>
                        <Route path='/login' element={<Home /> } />
                        <Route path='/register' element={<Register /> } />
                        <Route path='/change_pass' element={<ChangePass /> } />

                        <Route path='/investments' element={<InvestmentsPage /> } />
                        <Route path='/clients' element={<ClientsPage /> } />
                        <Route path='/docs' element={<DocsPage /> } />
                        <Route path='/texts' element={<TextEditPage /> } />
                        <Route path='/settings' element={<SettingsPage /> } />
                        <Route path='/logs' element={<LogsPage /> } />
                        <Route path='/network' element={<NetworkPage />} />
                        <Route path='/timeline' element={<TimelinePage />} />



                        {/* maybe usefull in future */}
                        <Route path='/transactions' element={<TransactionsPage /> } />

                        <Route path="*" element={<Error404 />} />

                    </Routes>
                </BrowserRouter>
            </MainContextProvider>
        </>
    );
}

export default App;
