/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { MainContext } from '../contexts/MainContext'
import {Link} from 'react-router-dom'
import '../css/app.css'
import './css/ChangePass.css'

function Register( props ) {
    const { changePassword, rootState, isLoggedIn, userData, getError, getTableWhereApi } = useContext(MainContext);
    const [isFlaged, setFlaged] = useState(false);
    const { isAuth } = rootState;

    let getUserData = userData
    
    const initialState = {
        userInfo: {
            user_id: null,
            password: '',
            passwordRepet: '',
        },
        errorMsg: '',
        successMsg: '',
    }
    const [state, setState] = useState(initialState);
    const [userName, setUserName] = useState("");


    useEffect(() => {
        isLoggedIn()
    },[])

    useEffect(() => {
        getUserIdFromUrl()

        if (getUserData){
            setState({
                ...state,
                userInfo: {
                    ...state.userInfo,
                    user_id: getUserData.id
                }
            });
        }
    }, [getUserData])

    const getUserIdFromUrl = async () => {
        let url = new URL(window.location.href)
        let getUserId = url.searchParams.get("user_id")
        if (getUserId) {
            let reqUserData = await getTableWhereApi('user_data', 'id', getUserId, 'int')
            if (!Array.isArray(reqUserData)) {
                console.warn("> Error getUserIdFromUrl()", reqUserData)
                getError("Nastala chyba při načítání dat. [reqUserData()]")
                return
            }
            getUserData = reqUserData[0]
            setUserName(" pro uživatele " + getUserData.name + " " + getUserData.surname + " id: " + getUserData.id)
            
            setState({
                ...state,
                userInfo: {
                    ...state.userInfo,
                    user_id: getUserData.id
                }
            });
        }
    }


    // On Submit the Registration Form
    const submitForm = async (event) => {
        event.preventDefault();
                
        if(state.userInfo.password !== state.userInfo.passwordRepet) {
            setFlaged(true)
        } else {
            setFlaged(false)

            const data = await changePassword(state.userInfo);

            console.log(data)

            if (data.success) {
                setState({
                    ...initialState,
                    successMsg: data.message,
                });
            }
            else {
                setState({
                    ...state,
                    successMsg: '',
                    errorMsg: data.message
                });
            }
            setTimeout(function() {
                setState({
                    ...state,
                    successMsg: '',
                    errorMsg: ''
                })
            }, 3000)
        }
    }

    // On change the Input Value (username, password)
    const onChangeValue = (e) => {
        setState({
            ...state,
            userInfo: {
                ...state.userInfo,
                [e.target.name]: e.target.value
            }
        });
        

    }

    // Show Message on Success or Error
    let successMsg = '';
    let errorMsg = '';
    if (state.errorMsg) {
        errorMsg = <div className="notification notification--error">{state.errorMsg}</div>;
    }
    if (state.successMsg) {
        successMsg = <div className="notification notification--success">{state.successMsg}</div>;
    }


    let login_page_bg = ''
    if (props.login_bg) {
        login_page_bg = 'login_page'
    } else {
        login_page_bg = ''
    }

    if(isAuth){
        return (
            <div className={`_loginRegister ${login_page_bg} change_pass_container`}>

                {errorMsg}
                {successMsg}
                
                <div className="register_form_container">
                    <Link to="/"> <div className="close_bnt"></div></Link>

                    <div className="container content_box cb-small register_container">
                        <h1 className="main_h1">Změna hesla {userName}</h1>

                    <form onSubmit={submitForm} noValidate>
                        <div className="reg_inner_conainer">
                            <div className="form-control">
                                <label className="text-gray inp-label">nové heslo</label>
                                <div className="input input-fullWidth">
                                    <input placeholder="••••••" name="password" className="input" type="password" required value={state.userInfo.password} onChange={onChangeValue} />
                                </div>
                            </div>
                            <div className="form-control">
                                <label className="text-gray inp-label">zopakovat heslo</label>
                                <div className="input input-fullWidth">
                                    <input placeholder="••••••" name="passwordRepet" className={isFlaged ? "has-error input" : "input"} type="password" required value={state.userInfo.passwordRepet} onChange={onChangeValue} />
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className="row reg_btns">
                                <Link to="/" ><button className="button button--outlined button--small aligner aligner--centerVertical back_btn">zpět</button></Link>

                                <div className="form-control">
                                    <button type="submit" className="button button--primay button--small aligner aligner--centerVertical">Změnit</button>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
                </div>

            </div>
        );
    }
    return <h2>Error you are not loged in.</h2>;
}
export default Register
