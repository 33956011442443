import {
    START_YEAR,
    NUM_OF_YEARS,
    MONTH_NAMES,
    MONTHS_PER_YEAR,
    QUARTERS_PER_YEAR,
    MONTHS_PER_QUARTER,
    NUM_OF_MONTHS,
    MAX_TRACK_START_GAP,
    MAX_ELEMENT_GAP,
    MAX_MONTH_SPAN,
    MIN_MONTH_SPAN,
} from './timelineConstants'

import { hexToRgb, colourIsLight, addMonthsToYear, addMonthsToYearAsDate, nextColor, randomTitle } from './timelineUtils'

// convert timestemp string to js Date() format
const convertTimestamp = (timestemp) => {
    let slitDateTime = timestemp.split('_')
    let slitDate = slitDateTime[0].split('.')
    let slitTime = slitDateTime[1].split(':')

    let date = new Date(parseInt(slitDate[2]), parseInt(slitDate[1]) - 1, parseInt(slitDate[0]), parseInt(slitTime[0]), parseInt(slitTime[1]), parseInt(slitTime[2]))
    return date
}

export const buildQuarterCells = () => {
    const v = []
    for (let i = 0; i < QUARTERS_PER_YEAR * NUM_OF_YEARS; i += 1) {
        const quarter = (i % 4) + 1
        const startMonth = i * MONTHS_PER_QUARTER
        const s = addMonthsToYear(START_YEAR, startMonth)
        const e = addMonthsToYear(START_YEAR, startMonth + MONTHS_PER_QUARTER)
        v.push({
            id: `${s.year}-q${quarter}`,
            title: `Q${quarter} ${s.year}`,
            start: new Date(`${s.year}-${s.month}-01`),
            end: new Date(`${e.year}-${e.month}-01`),
        })
    }
    return v
}

export const buildMonthCells = () => {
    const v = []
    for (let i = 0; i < MONTHS_PER_YEAR * NUM_OF_YEARS; i += 1) {
        const startMonth = i
        const start = addMonthsToYearAsDate(START_YEAR, startMonth)
        const end = addMonthsToYearAsDate(START_YEAR, startMonth + 1)
        v.push({
            id: `m${startMonth}`,
            title: MONTH_NAMES[i % 12],
            start,
            end,
        })
    }
    return v
}

export const buildTimebar = () => [
    {
        id: 'Čtvrtletí',
        title: 'Čtvrtletí',
        cells: buildQuarterCells(),
        style: {},
    },
    {
        id: 'Měsíce',
        title: 'Měsíce',
        cells: buildMonthCells(),
        useAsGrid: true,
        style: {},
    },
]

export const buildElement = ({ trackId, start, end, i, title }) => {
    const bgColor = nextColor()
    const color = colourIsLight(...hexToRgb(bgColor)) ? '#000000' : '#ffffff'
    return {
        id: `t-${trackId}-el-${i}`,
        title: title,
        start,
        end,
        style: {
            backgroundColor: `#${bgColor}`,
            color,
            borderRadius: '8px',
            boxShadow: '1px 1px 20px rgba(0, 0, 0, 0.15)',
            textTransform: 'capitalize',
        },
    }
}

export const buildTrackStartGap = () => Math.floor(Math.random() * MAX_TRACK_START_GAP)
export const buildElementGap = () => Math.floor(Math.random() * MAX_ELEMENT_GAP)

export const buildElements = trackId => {
    const v = []
    let i = 1
    let month = buildTrackStartGap()

    while (month < NUM_OF_MONTHS) {
        let monthSpan = Math.floor(Math.random() * (MAX_MONTH_SPAN - (MIN_MONTH_SPAN - 1))) + MIN_MONTH_SPAN

        if (month + monthSpan > NUM_OF_MONTHS) {
            monthSpan = NUM_OF_MONTHS - month
        }
        let title = randomTitle()

        const start = addMonthsToYearAsDate(START_YEAR, month)
        const end = addMonthsToYearAsDate(START_YEAR, month + monthSpan)
        v.push(
            buildElement({
                trackId,
                start,
                end,
                i,
                title
            })
        )
        const gap = buildElementGap()
        month += monthSpan + gap
        i += 1
    }

    return v
}

export const buildTrack = trackId => {
    return {
        id: `track-${trackId}`,
        title: `Track ${trackId}`,
        elements: buildElements(trackId),
        tracks: null,
        // hasButton: true,
        // link: 'www.google.com',
        isOpen: false,
    }
}


export const buildInvTrack = (trackId, inv) => {
    let i = 0
    const bgColor = nextColor()
    const color = colourIsLight(...hexToRgb(bgColor)) ? '#000000' : '#ffffff'

    let start = convertTimestamp(inv.start_date)
    let end = convertTimestamp(inv.end_date)
    // start = start.toISOString()
    // end = end.toISOString()

    console.log(start, end)

    let invElement = {
        id: `t-${trackId}-el-${i}`,
        title: `${inv.id} - ${inv.userData.name} ${inv.userData.surname}`,
        start,
        end,
        style: {
            backgroundColor: `#${bgColor}`,
            color,
            borderRadius: '8px',
            boxShadow: '1px 1px 20px rgba(0, 0, 0, 0.15)',
            textTransform: 'capitalize',
        },
        invData: inv,
    }
    return {
        id: `track-${trackId}`,
        title: `Investice ${trackId}`,
        elements: [invElement],
        tracks: null,
        // hasButton: true,
        // link: 'www.google.com',
        isOpen: false,
    }
}