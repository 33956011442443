/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { MainContext } from '../contexts/MainContext'
import './css/Settings.css'
// import { Prompt } from 'react-router'
import Loading from './Loading'
import ChangeUserPerm from './ChangeUserPerm'

function Settings(){
    const { userData, postTableApi, getSuccess, getTableApi, getError } = useContext(MainContext);

    const [systemDataEdit, setSystemDataEdit] = useState({
        system_email: '',
        deposit_bank_account: '',
        deposit_bank_account_iban: ''
    })
    const [userAccounts, setUserAccounts] = useState([])
    const [unsaved, setUnsaved] = useState(false)
    const [showUserPermEdit, setShowUserPermEdit] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)
   

    useEffect(() => {       // wait for userData to be loaded than run function
        if(userData.loaded !== false){            
            initSystemData()
            getAccountsData()
        }
    }, [userData])

    const initSystemData = async () => {
        let setDataObject = {}
        let reqSystemData = await getTableApi('system_data')
        if (!Array.isArray(reqSystemData) || reqSystemData.length === 0){
            getError('Nastala chyba načítání dat ' + reqSystemData)
            return
        }

        reqSystemData.forEach(item => {
            setDataObject[item.item] = item.value
        })
        
        setSystemDataEdit(setDataObject)
    }

    const updateContent = (e, key) => {
        let data = systemDataEdit[key];
        data = e.target.value;
        setUnsaved(true)
        setSystemDataEdit({
            ...systemDataEdit,
            [key]: data
        })
    }
    
    const updateTable = async () => {
        let reqSystemData = await getTableApi('system_data')
        if (!Array.isArray(reqSystemData) || reqSystemData.length === 0) {
            getError('Nastala chyba načítání dat ' + reqSystemData)
            return
        }

        reqSystemData.forEach(item => {
            item.value = systemDataEdit[item.item]
            item.change_user_id = userData.id
        })
        
        let tableObj = {
            data: reqSystemData,
            changes: [0,1,2],
            post_table: 'system_data'
        }
        let response = await postTableApi(tableObj)

        if(response && response.hasOwnProperty('success') && response.success === 0){
            getError("Nastala chyba při ukládání.")
            return
        }

        setTimeout(async function(){
            initSystemData()
            setUnsaved(false)

           getSuccess("Uloženo")
        }, 500)
    }

    const getAccountsData = async () => {
        let reqUsers = await getTableApi('users')
        let reqUserData = await getTableApi('user_data')

        if ((reqUsers && reqUsers.hasOwnProperty('success') && reqUsers.success === 0) || !Array.isArray(reqUsers)) {
            getError("Nastala chyba: getAccountsData() " + reqUsers)
            return
        }

        if ((reqUserData && reqUserData.hasOwnProperty('success') && reqUserData.success === 0) || !Array.isArray(reqUserData)) {
            getError("Nastala chyba: getAccountsData() " + reqUserData)
            return
        }

        reqUserData = reqUserData.filter(user => parseInt(user.id) >= 10001)

        let userDataArray = []
        reqUserData.forEach((item, i) => {
            let user = reqUsers.find(user => user.id === item.id)
            if (!user) {
                console.error(">> user_data X users inconsistency !! ", item.id)
                return
            }

            let userRole = 'undefined'
            if (user.user_type){
                if(user.user_type === "user"){
                    userRole = "uživatel"
                }else if(user.user_type === "admin"){
                    userRole = "admin"
                }else if(user.user_type === "dealer"){
                    userRole = "obchodník"
                } else {
                    userRole = "undefined"
                }
            }

            let userAccountRecord = {
                id: item.id,
                name: item.name + " " + item.surname,
                role: userRole

            }
            userDataArray.push(userAccountRecord)
        })
        setUserAccounts(userDataArray)

    }

    const selectUser = async (user) => {
        setSelectedUser(user)
        setShowUserPermEdit(true)
    }
    

    const handleClose = () => {
        setShowUserPermEdit(false)
        getAccountsData()
    }

    if(userData.hasOwnProperty('loaded') && !userData.loaded){
        return (
            <Loading />
        )
    }

    if (showUserPermEdit) {
        return (<>
            <ChangeUserPerm close={handleClose} user={selectedUser} />
        </>)
    }

    return (<>

        {/* <Prompt
            when={unsaved}
            message='Máte neuložené změny, opravdu chcete opustit stránku?'
        />
     */}
        <div className="fullpage_component_wapper">
            <div className="container content_box component_cb_fullpage settings_component">
                <div className="fullpage_component_headline">
                    <h3 className="cb_fullpage_label_right">Nastavení účtu</h3>
                    <p className="cb_fullpage_sublabel_right">Nastavení Vašeho profilu a informací</p>
                </div>

                <div className="fullpage_component_content_wrapper">

                    <div className="settings_grid">
                        <div className='grid_left'>
                            <div className="container content_box component_cb grid_box">
                                <p className="cb_label_left">Oprávnění uživatelů</p>

                                <div className='list_wrapper'>

                                    <div className="acm_line line_legend">
                                        <p>id</p>
                                        <p>jméno</p>
                                        <p>oprávnění</p>
                                    </div>
                                    <div className='acm_line_box_wrapper'>
                                        {Array.isArray(userAccounts) && userAccounts.map((data, i) => {
                                            return (
                                                <div className='acm_line_box' key={i} onClick={() => { selectUser(data) }} >
                                                    <p>{data.id}</p>
                                                    <p>{data.name}</p>
                                                    <p>{data.role}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>

                            </div>



                        </div>

                        <div className='grid_right'>
                            <div className="container content_box component_cb input_container grid_box">
                                <p className="cb_label_left">Systémové data</p>
                                <div className="input_container_wrapper">

                                    <div className="text_edit_item mid_size_input">
                                        <label className="input_label">e-mail</label>
                                        <div className="input input-fullWidth">
                                            <input type="text" value={systemDataEdit.system_email} placeholder="email" onChange={e => updateContent(e, 'system_email')} />
                                        </div>
                                    </div>

                                    <div className="text_edit_item mid_size_input">
                                        <label className="input_label">Číslo bankovního účtu pro vklad</label>
                                        <div className="input input-fullWidth">
                                            <input type="text" value={systemDataEdit.deposit_bank_account} placeholder="00000000000/0000" onChange={e => updateContent(e, 'deposit_bank_account')} />
                                        </div>
                                    </div>

                                    <div className="text_edit_item mid_size_input">
                                        <label className="input_label">Číslo IBAN pro vklad</label>
                                        <div className="input input-fullWidth">
                                            <input type="text" value={systemDataEdit.deposit_bank_account_iban} placeholder="CZ00 0000 0000 0000 0000 000" onChange={e => updateContent(e, 'deposit_bank_account_iban')} />
                                        </div>
                                    </div>
                                    <br />
                                    <div className="action_box_wrpaper">
                                        <p className="label">{unsaved ? 'Uložit změny provedené na webu: ' : 'Nebyly provedeny žádné změny '} </p>
                                        <button onClick={updateTable} className="button button--small update_btn">Uložit</button>
                                    </div>

                                </div>
                            </div>



                        </div>



                    </div>

                </div>
            </div>
        </div>
    </>
    )
}

export default Settings

                        