/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { MainContext } from '../contexts/MainContext'
import './css/UserRequests.css'

function UserRequests(){
    const { userData, getTableApi, getError, postTableApi } = useContext(MainContext)

    // wait for userData to be loaded then run function
    useEffect(() => {
        if (userData.id ) {
            getRequestData()
        }
    }, [userData])

    const [requestData, setRequestData] = useState([])
    const [getUserData, setUserData] = useState([])

    const getRequestData = async () => {
        let reqRequestData = await getTableApi('user_requests')
        if (Array.isArray(reqRequestData)) {
            reqRequestData = reqRequestData.reverse()
            setRequestData(reqRequestData)
        }    
        let reqUserData = await getTableApi('user_data')
        if (Array.isArray(reqUserData)) {
            setUserData(reqUserData)
        }    
    }

    const handleStatusChange = async (req) => {
        let newReq = req
        newReq.completed = parseInt(req.completed) === 1 ? "0" : "1"
        let tableData = {
            data: [req],
            changes: [0],
            post_table: 'user_requests'
        }
        let postReqChanges = await postTableApi(tableData)
        if (!postReqChanges.hasOwnProperty('success') || postReqChanges.success !== 1) {
            getError("Nastala chyba: " + postReqChanges)
            return
        }
        getRequestData()
    }

    return(
        <div className="container content_box component_cb user_request_wrapper">
            <p className="container_box_label">Akce uživatelů</p>

            <div className="payment_calendar_container">

                <div className="payment_table_wrapper">
                    <div className="ptw_line line_legend">
                        <p>požadavek</p>
                        <p>jméno</p>
                        <p>částka</p>
                        <p>datum</p>
                    </div>

                    <div className="aux_table_wrapper_outer">
                        <div className="aux_table_wrapper">
                            {Array.isArray(requestData) && Array.isArray(getUserData) ? requestData.map((data, i) => {

                                let user = getUserData.find(user => user.id === data.user_id)
                                if (user){
                                    return (
                                        <div className={parseInt(data.completed) === 1 ? 'pmt_line_box completed' : 'pmt_line_box unread'} key={i} onClick={() => {handleStatusChange(data)}} >
                                            <p>{data.content}</p>
                                            <p>{user.name + " " + user.surname}</p>
                                            <p className="currency">{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(data.amount)}</p>
                                            <p>{data.timestemp.split("_")[0]}</p>
                                        </div>
                                    )
                                }
                            }) :
                            <p className="empty_label">Nebyly nalezeny žádné požadavky.</p>}
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default UserRequests
